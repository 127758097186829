import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Configpage() {
    const tableEntries = [
        {
            id: "1",
            ref: "Procress",
            Sno: "42",
            pageName: "WithdrawalFund",
            message: "Withdrawal is Under Construction. Withdrawal will be Available Soon...!	",
            permission: "1",
            updatedby: "admin",
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };


    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const [isUpdate, setUpdate] = useState(false);
    const handleUpdate = () => {
        setUpdate(!isUpdate);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Config Pages
                </h1>
                {isUpdate ? (
                    <div className=' rounded-lg mt-5 border '>
                        <div className='px-5 py-3 border-b  font-bold text-lg'>
                            <p>Config List</p>
                        </div>
                        <div className='flex flex-col gap-3 w-[100%] lg:w-[80%] mx-auto px-5 py-3'>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end font-bold md:w-[30%]'>PageName</p>
                                <input className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'></input>
                            </div>
                            <div className='flex flex-col md:flex-row gap-3'>
                                <p className='text-center md:text-end font-bold md:w-[30%] md:pt-3'>Message</p>
                                <textarea className='md:w-[50%] h-[125px] py-1 border outline-none px-3 rounded-md bg-transparent'></textarea>
                            </div>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end font-bold md:w-[30%] '>Permission</p>
                                <select className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'>
                                    <option className='text-black'>Active</option>
                                    <option className='text-black'>Inactive</option>
                                </select>
                            </div>
                            <div className='flex gap-3 justify-center'>
                                <button className='px-5 py-2 text-sm border border-white/50 font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' >Update Details</button>
                                <button className='px-5 py-2 text-sm border border-white/50 font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' >Delete Details</button>

                            </div>
                        </div>
                    </div>
                ) : (
                    <div className=' rounded-lg mt-5 border '>
                        <div className='px-5 py-3 border-b  font-bold text-lg'>
                            <p>Config List</p>
                        </div>
                        <div className='border-b px-5 py-3'>
                            <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' onClick={handleUpdate}>+ Add New</button>
                        </div>
                        <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                            <div className=' text-sm font-bold m-auto'>
                                Total Record : 4
                            </div>
                            <div></div>
                            <div></div>
                            <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                                <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
                                <div className='w-[25%] md:w-[100%]'>
                                    <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                                </div>
                            </div>
                            <div className=' text-sm font-bold m-auto'>
                                Page of 1 of 0
                            </div>
                        </div>
                    </div>
                )}
                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'>Ref</th>
                                    <th className='px-2'>SlNo</th>
                                    <th className='px-2'>PageName</th>
                                    <th className='px-2'>Message</th>
                                    <th className='px-2'>Permission</th>
                                    <th className='px-2'>Approved</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{entity.id}</td>
                                        <td className='px-5 md:px-2'>{entity.ref}</td>
                                        <td className='px-5 md:px-2'>{entity.Sno}</td>
                                        <td className='px-5 md:px-2'>{entity.pageName}</td>
                                        <td className='px-5 md:px-2'>{entity.message}</td>
                                        <td className='px-5 md:px-2'>{entity.permission}</td>
                                        <td className='px-5 md:px-2'>{entity.updatedby}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
