import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { MORALIS_API_KEY, SERVER_URL, TOKEN_ADDRESS, PROVIDER_URL, MBCPayOut_ContractAddress, MBCToken_ContractAddress } from '../../../config';
import { ethers, Wallet } from 'ethers';
import BigNumber from "bignumber.js";
import { MBCPayOut_abi } from '../../../ABI';
import {
  useWeb3ModalAccount, useWeb3ModalProvider
} from "@web3modal/ethers5/react";
export default function WithdrawIncome() {

  const user = useSelector((state) => state.user);
  const name = user[0].name;
  const email = user[0].email;
  const CusID = user[0].CusID;

  const [DepositAddress, setDepositAddress] = useState(null)
  const [IncomeAddress, setIncomeAddress] = useState(null)

  const [DepositBalance, setDepositBalance] = useState(0)
  const [Team, setTeam] = useState(0)
  const [Subscription, setSubscription] = useState(0)


  const [IncomeBalance, setIncomeBalance] = useState(0)

  const [tableEntries, settableEntries] = useState([])
  const [usdtBepAddress, setUsdtBepAddress] = useState('');
  const [usdtTrcAddress, setUsdtTrcAddress] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const { walletProvider, walletProviderType} = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const fetchTokenValues = async (address) => {
    try {
        const response = await fetch(`https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=bsc`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': MORALIS_API_KEY
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

    
      const targetToken = data.result.find(token => token.token_address.toLowerCase() === TOKEN_ADDRESS.toLowerCase());
      console.log("balance", data)
    
      if (targetToken) {
        const balance = parseFloat(targetToken.balance) / (10 ** targetToken.decimals); 

        return balance;
      } else {
        return 0;
      }

    } catch (error) {
      console.error('Error fetching token balance:', error);
      return null;
    }
  };

  


  
  const fetchAddress = async () => {
    const response = await axios.get(`${SERVER_URL}/api/fetchAddress?email=${email}`);
    if (response.data.status === true) {
      setDepositAddress(response.data.address.deposit_wallet);
      setIncomeAddress(response.data.address.income_wallet)
    }
  }

  const fetchHistory = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/fetchWithdrawHistory?CusID=${CusID}`);
      if (response.data.status === true) {
        settableEntries(response.data.data);
        const total = response.data.data.reduce((acc, entry) => acc + parseFloat(entry.amount), 0);
        setTotalAmount(total);
      }
    } catch (error) {
      console.error("error : ", error)
    }

  }

  useEffect(() => {
    if (email !== '') {
      fetchAddress();
    }
    if (CusID !== null) {
      fetchHistory()
    }
  }, [email, CusID])

  const getUserDetails = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
      let contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
      let getDetails = await contract.getuserDetails(IncomeAddress);
      setIncomeBalance(parseFloat(getDetails[7] / 1e6));
      setSubscription(parseFloat(getDetails[4] / 1e6));
      setTeam(parseInt(getDetails[6]));

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (IncomeAddress !== null) {
      getUserDetails()
    }
  }, [IncomeAddress])


  
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }


  const fetchDatas = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/getUsersDatas?CusID=${CusID}`);
      if (response.data && response.status === 200) {
        const data = response.data.data[0];
        // setUserData(data);
        setUsdtBepAddress(data.withdraw_address || '');
        setUsdtTrcAddress(data.withdraw_wallet2 || '');

      } else {
        console.error("Failed to fetch user data:", response.data.message);
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  useEffect(() => {
    if (CusID) {
      fetchDatas();
    }
  }, [CusID]);


  const withdrawRewards = async () => {
    if(!isConnected){
      toast.error("Please Connect Wallet First");
      return false;
    }
    //else{
    //   const updateAddress = await axios.post(`${SERVER_URL}/api/migrateNewAddress`, {
    //     address: address,
    //     CusID: CusID,
    //   });
    // }
    if (usdtBepAddress === '') {
      toast.error("Please add withdrawal address");
      
      setTimeout(() => {
      window.location = '/User/dashboard?tab=account&profile=Payment-Details';
    }, 3000);
      return false;
    }

    if (IncomeBalance <= 0) {
      toast.error("No Rewards to withdraw!");
      return false;
    }
    const toastId = toast.loading("Processing withdrawal...");

    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const TokenContract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
      const tx = await TokenContract.withdrawRewards(usdtBepAddress
        , {
          gasPrice: ethers.utils.parseUnits("5", "gwei")
          // gasLimit: 300000
        }
      );
      console.log(tx);
      await tx.wait();
      const withdraw = await axios.post(`${SERVER_URL}/api/withdrawRewards`, {
        incomeAddress: IncomeAddress,
        email: email,
        amount: IncomeBalance,
        CusID: CusID,
        receiverAddress: usdtBepAddress,
        hash: tx.hash,
      });

      if (withdraw.data.status === true) {
        toast.dismiss(toastId);
        toast.success('Withdrawal Successful!');
        fetchHistory();
      } else {
        toast.dismiss(toastId);
        toast.error("Withdrawal Failed!");
      }

    } catch (error) {
      console.error("Error withdrawing rewards:", error);
      toast.dismiss(toastId);
      toast.error("Withdrawal Failed!");
    }
  };



  const openImageInNewTab = (entity) => {
    window.open(`https://bscscan.com/tx/${entity}`, '_blank');
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
  };


  return (
    <div className='h-[88vh] '>
      <div className='md:pl-5 py-5 md:pr-10 font-poppins relative '>
        <div className='z-0'>
          <div className='md:w-[70%] mx-auto flex flex-col md:flex-row justify-center gap-5 '>
            <div className='border-[2px] md:w-[50%] border-white/50 rounded-md text-center px-2 py-5 shadow-md flex items-center justify-center gap-3'>
              <div><img src='/assets/wallet.png' className='mx-auto w-[60%]'></img></div>
              <div>
                <h1 className='tracking-wider uppercase text-sm'>Income Wallet</h1>
                <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {IncomeBalance}</p>
                <p className='text-xs'>Analytics for Income Balance</p></div>
            </div>
            <div className='border-[2px] md:w-[50%] border-white/50 rounded-md text-center p-2 py-5 shadow-md flex items-center justify-center gap-3'>
              <div><img src='/assets/wallet.png' className='mx-auto w-[60%]'></img></div>
              <div><h1 className='tracking-wider uppercase text-sm'>Total Withdrawal</h1>
                <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {totalAmount}</p>
                <p className='text-xs'>Withdrawal History</p></div>
            </div>
          </div>
          <div className='md:w-[70%] mx-auto px-7 py-3 rounded-md mt-10'>
            <p className='font-bold'>Important Note :</p>
            <ul className='mt-2 pl-10 list-disc'>
              <li>Minimum withdrawal is 1 USD.</li>
              <li>KYC is mandatory before withdrawal.</li>
            </ul>
          </div>
          {/* <div className='md:w-[70%] mx-auto bg-[#E43875AB] shadow-md shadow/black/30 border border-white/25 border-dashed px-7 py-3 rounded-md mt-5'>
            <p className='font-bold'>Withdraw Income</p>
            <div className='mt-2 flex justify-center'>
              <div className='px-5 md:px-10 py-3 text-center bg-white rounded-xl font-bold'>
                <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#E43875] to-[#F98736]'>Please</p>
                <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#E43875] to-[#F98736]'>Set USDT BEP-20 Address...!</p>
                <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#E43875] to-[#F98736]'>Set USDT TRC-20 Address...!</p>
              </div>
            </div>
          </div> */}
          {/* <div className='md:w-[70%] mx-auto flex justify-center mt-5'>
            <p className='mr-5'>Withdraw address : </p>
            <input className='rounded-lg bg-transparent border border-white w-[70%] h-[30px]'
              type='text'
            />
          </div> */}
          <div className='md:w-[70%] mx-auto mt-5'>
            <button className='w-full py-3 text-sm font-bold rounded-md bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30'
              onClick={withdrawRewards}>
              Withdraw Rewards</button>
          </div>
          <div className='rounded-2xl mt-5'>
            <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
              <div className='md:w-[40%]'>
                <p className='my-auto font-bold text-xl '>Withdraw History</p>
              </div>
              <div className='flex items-center'>
                <p className='text-end text-sm px-3 my-auto'>Show no of entity
                  <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                    <option className='text-black' value="5">5</option>
                    <option className='text-black' value="7">7</option>
                    <option className='text-black' value="10">10</option>
                  </select>
                </p>
              </div>
            </div>
            <div className='mb-5 border border-dashed rounded-xl overflow-hidden overflow-x-auto'>
              <table className='w-full text-center  '>
                <thead className='uppercase font-bold text-sm border-b py-3'>
                  <tr>
                    <th className='py-3 px-2'>SI.no</th>
                    <th className='px-2'>CustID</th>
                    <th className='px-2'>Date & Time	</th>
                    <th className='px-2'>Amount ($)	</th>
                    <th className='px-2'>Chain</th>
                    <th className='px-2'>TxID</th>
                    <th className='px-2'>Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y'>
                  {currentEntities.map((entity, index) => (
                    <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                      <td className='px-5 md:px-0 font-Artemus'>{index + 1}</td>
                      <td className='px-5 md:px-0'>{entity.CusID}</td>
                      <td className='px-5 md:px-0'>{formatDate(entity.created_at)}</td>
                      <td className='px-5 md:px-0'>{Number(entity.amount).toFixed(2)}</td>
                      <td className='px-5 md:px-0'>{entity.network}</td>
                      <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.transaction_hash)}>
                        {entity.transaction_hash.slice(0, 10)}...
                      </td>
                      <td className='px-5 md:px-0'>{entity.status}</td>
                    </tr>
                  ))}
                  {/* Add empty rows if needed */}
                  {Array.from({ length: emptyRowCount }).map((_, index) => (
                    <tr key={`empty-${index}`} className='h-16'>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mt-2 flex justify-between text-xs px-3'>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
              <div className='my-auto flex justify-center items-center gap-1'>
                <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
              </div>
              <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
            </div>
          </div>
        </div>
        {/*Comming Soon content*/}
        {/* <div className='z-10 w-full top-0 absolute model-overlay h-screen backdrop-blur-md bg-black/20 flex items-center justify-center'>
          <div className='md:w-[60%] text-center -translate-y-20  p-5'>
            <h1 className='font-Artemus text-7xl uppercase font-bold bg-gradient-to-r from-[#F23984] to-[#FB9136] bg-clip-text text-transparent'>Coming soon</h1>
            <p className='text-white text-wrap font-semibold '>Unlock Unlimited Opportunities with Our Revolutionary MLM Platform!
            Stay connected and be ready to take the first step towards building your wealth.</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}