import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './route';
import './fonts/Artemus.woff';
import './fonts/Artemus.ttf';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import ReactGA from "react-ga4";
import Intercom from '@intercom/messenger-js-sdk';
import { Provider } from 'react-redux';
import { store } from './store';

Intercom({
  app_id: 'xvklq4eq',
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'e489cd9d43d5d2eac184b6ad121ef4fb';


// 2. Set chains
const bscMainnet = {
  chainId: 56,
  name: 'Binance Smart Chain Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com/',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
};

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mybusinessclub.com/',
  icons: ['https://mybusinessclub.com/assets/MBC_logo.png']
};

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [bscMainnet],
  themeMode: 'dark',
  projectId,
  enableEmail: true,
  defaultChain: bscMainnet,
  themeVariables: {
    '--w3m-accent': 'linear-gradient(to right, #E43875, #F98736)', 
  },
  enableAnalytics: true
});

// const TRACKING_ID = "G-RKMDER1ZTK";
const TRACKING_ID = "G-MYG08T2T0E";

ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Mybusinessclub"
    });
  }, []);

  return (
    <Routers />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
