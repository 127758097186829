import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Layout/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser, SET_TOKEN } from '../../store';
import toast, { Toaster } from 'react-hot-toast';
import { SERVER_URL } from '../../config';
AOS.init();

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({ password: '', confirmPassword: '' });

    const handlePasswordToggle = () => {
        setVisible(!visible);
    };

    const validateInputs = () => {
        let valid = true;
        let tempErrors = { password: '', confirmPassword: '' };

        if (!password) {
            tempErrors.password = 'Password is required';
            valid = false;
        }

        if (password !== confirmPassword) {
            tempErrors.confirmPassword = 'Passwords do not match';
            valid = false;
        }

        setErrors(tempErrors);
        return valid;
    };

    const LoginUser = async () => {
        if (validateInputs()) {
            try {
                const token = new URLSearchParams(window.location.search).get('token');
                const response = await axios.post(`${SERVER_URL}/api/reset-password-verification`, { token, password });

                if (response.data.status === 'success') {
                    toast.success('Password reset successful.');
                    setTimeout(() => navigate('/Login'), 2000);
                } else {
                    toast.error(response.data.message || 'Failed to reset password. Please try again.');
                }
            } catch (error) {
                console.error('Error during password reset:', error);
                toast.error('An error occurred. Please try again.');
            }
        } else {
            console.log('Validation failed');
        }
    };

    return (
        <div>
            <Header />
            <Toaster />

            <div
                className="sec-1 bg-black bg-cover min-h-screen font-poppins text-white pt-24 overflow-hidden"
                style={{ backgroundImage: 'url(../assets/NewMBCbg.png)' }}
            >
                <div className="w-[90%] mx-auto flex justify-center items-center py-10">
                    <div className="flex lg:flex-row flex-col justify-center">
                        <div className="lg:w-[60%] w-full" data-aos="fade-right" data-aos-duration="2000">
                            <div>
                                <h1 className="md:text-6xl text-4xl font-Artemus font-bold uppercase text-shadow-custom">My business club</h1>
                                <p className="font-bold md:text-lg text-sm mt-2">Welcome to private membership “Business Club”</p>
                            </div>
                            <div className="mt-10 w-full">
                                <img src="assets/Rectangle_39637.png" className="lg:w-10/12" alt="Business Club" />
                                <div className="flex justify-end">
                                    <div className="bg-white/60 sm:py-7 py-4 sm:px-5 px-2 rounded-2xl border sm:w-32 w-24 lg:-translate-x-20 md:-translate-y-24 -translate-y-10 backdrop-blur-md">
                                        <img src="../assets/Icon (2).png" alt="Icon" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-[40%]" data-aos="fade-left" data-aos-duration="2000">
                            <div className="border-2 rounded-md py-5 md:px-10 px-3">
                                <div>
                                    <p className="sm:text-4xl text-4xl font-bold">FORGET PASSWORD</p>
                                    <p className="font-bold mt-1 text-sm px-2">Sign in with email address</p>
                                </div>
                                <div className="border-b-2 py-7">
                                    <div className="mt-3 relative">
                                        <input
                                            type={visible ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className={`w-full rounded-lg focus:outline-none py-3 px-10 text-[#A4A4A4] text-sm ${errors.password && 'border border-red-500'}`}
                                            placeholder="Enter the password"
                                        />
                                        <FontAwesomeIcon icon={faLock} className="text-[#A4A4A4] absolute left-3 top-3.5" />
                                        <button
                                            onClick={handlePasswordToggle}
                                            className="absolute top-0 bottom-0 right-0 flex justify-center items-center h-full w-[40px]"
                                        >
                                            {visible ? (
                                                <FontAwesomeIcon icon={faEye} size="sm" className="text-gray-400" />
                                            ) : (
                                                <FontAwesomeIcon icon={faEyeSlash} size="sm" className="text-gray-400" />
                                            )}
                                        </button>
                                    </div>
                                    {errors.password && <p className="px-2 text-xs mt-1">{errors.password}</p>}

                                    <div className="mt-3 relative">
                                        <input
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className={`w-full rounded-lg focus:outline-none py-3 px-10 text-[#A4A4A4] text-sm ${errors.confirmPassword && 'border-red-500'}`}
                                            placeholder="Enter the confirm password"
                                        />
                                        <FontAwesomeIcon icon={faLock} className="text-[#A4A4A4] absolute left-3 top-3.5" />
                                    </div>
                                    {errors.confirmPassword && <p className="px-2 mt-1 text-xs">{errors.confirmPassword}</p>}

                                    <div className="mt-5 cursor-pointer" onClick={LoginUser}>
                                        <div className="shadow-lg shadow-black/30 bg-white py-1 px-2 rounded-xl">
                                            <div className="w-full rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center">Confirm</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
