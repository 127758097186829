import React, { useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { faChevronRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();

const FAQ = () => {
  const faq = [
    {
      question: "Why should I join My Business Club?",
      answer: "<strong>My Business Club (MBC)</strong> is all about <strong>empowering entrepreneurs</strong> with <strong>real-time rewards.</strong> Our membership offers <strong>instant payouts</strong> through <strong>Smart Contract Pay,</strong> meaning you get paid the moment a sale or referral is made<strong>—no delays, no hassle.</strong> If you value speed, transparency, and growth, MBC is the place for you.",
    },
    {
      question: "How do real-time payments work?",
      answer:
        "Our <strong>smart contracts</strong> handle all transactions automatically. This means as soon as you close a deal or make a referral, you get paid <strong>immediately</strong>. It’s fast, secure, and completely transparent, giving you instant access to your earnings.",
    },
    {
      question: "What is the MBC Membership Plan?",
      answer:
        "The MBC Membership Plan is a tiered membership program that allows you to join the MBC community, earn commissions, and access exclusive benefits. Starting with a free membership, you can upgrade to higher levels like Silver, Gold, or even President to unlock more perks and opportunities.",
    },
    {
      question: "How do I sign up for the MBC Membership?",
      answer:
        'Signing up is easy! Simply click the "Sign Up Now" button on our website and follow the registration process. You can start with a free membership and upgrade whenever you’re ready to take advantage of more benefits.',
    },
    {
      question: "What are the benefits of upgrading my membership?",
      answer:
      "Each upgrade unlocks new perks such as increased earning potential, access to exclusive tools, personalized support, and investment opportunities like Real-World Asset NFTs. The higher your membership tier, the more rewards you gain.",    
    },
    {
      question: "Can I upgrade my membership at any time?",
      answer:
        "Yes! You can upgrade your membership at any time by paying the difference between your current plan and the next level within 90 days. This flexibility allows you to grow at your own pace.",
    },
    {
      question: "What happens if I don’t upgrade within 90 days?",
      answer: 
      "If you don’t upgrade within the 90-day window, you can still upgrade later, but you’ll need to pay the full price of the desired membership tier. Upgrading within 90 days helps you take advantage of a seamless transition."
    },
    {
      question: "How much commission do I earn from referrals?",
      answer:
      "All members, including free members, earn a <strong>10% commission</strong> on MBC referrals. This means that every new member you bring into the MBC community can help you earn extra income."
    },
    {
      question: "What is the Ainstein License, and how does it benefit me?",
      answer:
      "The Ainstein License gives you access to advanced tools for just <strong>$100,</strong> with <strong>50% of the fee converted to Commissionable Volume (CV).</strong> It’s an excellent way to enhance your earning potential while using top-notch resources."
    },
    {
      question: "Are there any hidden fees or charges?",
      answer:
      "No, there are no hidden fees. The membership fees are transparent and clearly outlined, depending on the tier you choose. You only pay the difference when upgrading within the 90-day period."
    },
    {
      question: "What kind of support is available for members?",
      answer:
      "MBC members receive tailored support based on their membership level. Our team is here to assist you with any questions and provide guidance to help you maximize your membership benefits."
    },
    {
      question: "How do I contact customer support if I have more questions?",
      answer:
      "You can reach out to our customer support team through the contact form on our website. We’re here to help you with any questions or concerns."
    }
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1); // Collapse if already expanded
    } else {
      setExpandedIndex(index); // Expand the selected item
    }
  };

  return (
    <div>
      <div
        className="  sec-1 bg-black bg-cover font-poppins text-white"
        style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
      >
        <Header />

        <div className="pt-28">
          <h1 className="text-6xl 2xl:text-7xl text-center font-bold font-Artemus uppercase text-shadow-custom" data-aos="fade-up" data-aos-duration="">faq’s</h1>
          <p className="font-semibold text-lg text-center" data-aos="zoom-in" data-aos-duration="">Questions? Look here.</p>
        </div>

        <div className="lg:w-[55%] w-[90%] mx-auto mt-10 pb-10" data-aos="fade-up" data-aos-duration="1000">
          {faq.slice(0, 12).map((data, index) => (
            <div
              key={index}
              onClick={() => toggleExpand(index)}
              className={`text-white border rounded-md bg-gradient-to-r from-[#E43875] to-[#F98736]  cursor-pointer mt-2 duration-500 transform transition ease-in-out ${expandedIndex === index ? "h-full" : "h-full"
                }`}
            >
              <div className="flex ">
                <div className="w-[90%] p-3">
                  <h1 className="font-semibold text-lg py-2">{data.question}</h1>
                </div>
                <div className="w-[10%] flex items-center justify-center">
                  <button className="font-bold">
                    {expandedIndex === index ? (
                      <FontAwesomeIcon
                        icon={faMinus}
                        className={`transform transition-transform duration-500`}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={`transform transition-transform duration-500`}
                      />
                    )}
                  </button>
                </div>
              </div>
              <div
                className={`duration-300 px-5 bg-white ease-in-out overflow-hidden ${expandedIndex === index
                  ? "max-h-[250px]  md:max-h-[140px] py-5"
                  : "max-h-0"
                  }`}
              >
                <p
                  dangerouslySetInnerHTML={{ __html: data.answer }}
                  className="text-[#595959] font-medium duration-500"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FAQ;
