import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function Withdrawsummary() {

    const tableEntries = [
        {
            id: 1,
            txn_no: "6029433",
            CustID: "468831",
            Login_ID: "Dezzie333",
            Name: "Derek Milton Anthony Telles	",
            Mobileno: "7791764436",
            DateTime: "8/31/2024 9:20:37 AM	",
            Request_Amount: 187.50000000,
            Net_AMT: 178.125,
            deduction: 9.37500000,
            Req_Type: "USDT TRC-20",
            USDT: 178.125,
            INR: 178.12500000,
            INRRate: 1.00000000,
            Status: "Approved",
            USDT_TRC20_Address: "TMBjCjepXk7CTZJ27kqmWAgwjdnxVWvC9a	",
            Account_No: "",
            IFSC_Code: "",
            Holder_Name: "",
            Bank: "",
            TxID: "e5aa2cece43c0ff97feb6e43bae0c2689e9ad180ad0e21eadca290ca1f0e3d8a	",
            PayCurrency: "USDT TRC-20	",
            Approveby: "1",
            ApproveDate: "9/2/2024 3:12:11 PM	",
            Comment: "N/A",
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };


    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Withdrawal Details
                </h1>
                <div className='border rounded-md mt-2 p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                    <div className='flex md:grid gap-1 m-auto '>
                        <p className='text-sm font-bold'>Currency</p>
                        <select className='bg-transparent border rounded-md py-1 px-1 w-[100%] text-center outline-none'>
                            <option className='text-black'>USDT BEP-20</option>
                            <option className='text-black'>USDT TRC-20</option>
                        </select>
                    </div>
                    <div className='flex md:grid gap-1 m-auto '>
                        <p className='text-sm font-bold'>Status</p>
                        <select className='bg-transparent border rounded-md py-1 px-1 w-[100%] text-center outline-none'>
                            <option className='text-black'>Approved</option>
                            <option className='text-black'>Pending</option>
                            <option className='text-black'>Rejected</option>
                        </select>
                    </div>
                    <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                        <p className='text-sm font-bold text-nowrap w-[50%]'>Cust_ID / UserName</p>
                        <div className='w-[25%] md:w-[100%]'>
                            <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                        </div>
                    </div>
                    <div className='flex md:grid gap-1 m-auto'>
                        <div>
                            <button className='px-5 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Search</button>
                        </div>
                    </div>
                    <div className=' text-sm font-bold m-auto'>
                        <div>
                            <button className='px-5 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Export to Excel</button>
                        </div>
                    </div>
                </div>
                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div>

                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center text-[13px]'>
                            <thead className=' uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>Sr.No.</th>
                                    <th className='px-2'>txn_no	</th>
                                    <th className='px-2'>CustID	</th>
                                    <th className='px-2'>Login_ID</th>
                                    <th className='px-2'>Name</th>
                                    <th className='px-2'>Mobileno</th>
                                    <th className='px-2'>Date / Time</th>
                                    <th className='px-2'>Request_Amount($)	</th>
                                    <th className='px-2'>Net_AMT($)</th>
                                    <th className='px-2'>deduction	</th>
                                    <th className='px-2'>Req_Type	</th>
                                    <th className='px-2'>USDT</th>
                                    <th className='px-2'>INR</th>
                                    <th className='px-2'>INRRate</th>
                                    <th className='px-2'>Status	</th>
                                    <th className='px-2'>USDT TRC20 Address	</th>
                                    <th className='px-2'>Account_No	</th>
                                    <th className='px-2'>IFSC_Code	</th>
                                    <th className='px-2'>Holder Name	</th>
                                    <th className='px-2'>Bank</th>
                                    <th className='px-2'>TxID	</th>
                                    <th className='px-2'>PayCurrency</th>
                                    <th className='px-2'>Approveby</th>
                                    <th className='px-2'>ApproveDate</th>
                                    <th className='px-2'>Comment</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{entity.id}</td>
                                        <td className='px-5 md:px-2'>{entity.txn_no}</td>
                                        <td className='px-5 md:px-2'>{entity.CustID}</td>
                                        <td className='px-5 md:px-2'>{entity.Login_ID}</td>
                                        <td className='px-5 md:px-2'>{entity.Name}</td>
                                        <td className='px-5 md:px-2'>{entity.Mobileno}</td>
                                        <td className='px-5 md:px-2'>{entity.DateTime}</td>
                                        <td className='px-5 md:px-2'>{entity.Request_Amount}</td>
                                        <td className='px-5 md:px-2'>{entity.Net_AMT}</td>
                                        <td className='px-5 md:px-2'>{entity.deduction}</td>
                                        <td className='px-5 md:px-2'>{entity.Req_Type}</td>
                                        <td className='px-5 md:px-2'>{entity.USDT}</td>
                                        <td className='px-5 md:px-2'>{entity.INR}</td>
                                        <td className='px-5 md:px-2'>{entity.INRRate}</td>
                                        <td className='px-5 md:px-2'>{entity.Status}</td>
                                        <td className='px-5 md:px-2'>{entity.USDT_TRC20_Address}</td>
                                        <td className='px-5 md:px-2'>{entity.Account_No}</td>
                                        <td className='px-5 md:px-2'>{entity.IFSC_Code}</td>
                                        <td className='px-5 md:px-2'>{entity.Holder_Name}</td>
                                        <td className='px-5 md:px-2'>{entity.Bank}</td>
                                        <td className='px-5 md:px-2'>{entity.TxID}</td>
                                        <td className='px-5 md:px-2'>{entity.PayCurrency}</td>
                                        <td className='px-5 md:px-2'>{entity.Approveby}</td>
                                        <td className='px-5 md:px-2'>{entity.ApproveDate}</td>
                                        <td className='px-5 md:px-2'>{entity.Comment}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
