import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export default function UserActivity() {
    const tableEntries = [
        {
          id: "1",
          custId: "708944",
          sponserID: "389924",
          custname: "Charles Nwankwo",
          loginusername: "Zuky190",
          country: "Russia",
          currentrank: "Basic",
          registerdate: "5/9/2024 3:31:16 AM",
        },
      ];
    
      const [currentPage, setCurrentPage] = useState(1);
      const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    
      // Determine the current entries to display
      const indexOfLastEntity = currentPage * entitiesPerPage;
      const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
      const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
      const emptyRowCount = entitiesPerPage - currentEntities.length;
    
      // Handle previous and next page clicks
      const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
      };
    
      const handleNextClick = () => {
        setCurrentPage(prevPage => {
          const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
          return Math.min(prevPage + 1, totalPages);
        });
      };
    
      // Handle page number click
      const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
      };
    
      // Handle dropdown change for entities per page
      const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
      };
    
    
      // Calculate pagination details
      const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
      const maxPageNumbersToShow = 5;
      const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
      const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
      const pageNumbers = [];
    
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    
      const [selectedFrom, setSelectedFrom] = useState(null);
      const [selectedTo, setSelectedTo] = useState(null);
    
      const [isChangeName, setChangeName] = useState(false);
    
      const handleChangeName = () => {
        setChangeName(!isChangeName);
      }
    
      const [isEditing, setIsEditing] = useState(false);
      const [formData, setFormData] = useState({
        custID: '708944',
        custUserName: 'Zuky190',
        loginUserName: 'Zuky190',
        entryDate: '5/9/2024 3:31:16 AM'
      });
    
      const handleEdit = () => {
        setIsEditing(true);
      };
    
      const handleSave = () => {
        setIsEditing(false);
      };
    
      const handleCancel = () => {
        setIsEditing(false);
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
    <div className='p-3 md:p-7 mt-5 font-poppins'>
      <h1 className='text-2xl font-bold uppercase font-Artemus'>User Activity Log Detail</h1>
      <div className=' rounded-lg mt-5 border '>
        <div className='px-5 py-3 border-b  font-bold text-lg'>
          <p>Filter Your Search</p>
        </div>
        <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
          <div className=' text-sm font-bold m-auto'>
            Total Record : 4
          </div>
          <div className='flex md:grid gap-1 m-auto items-center'>
            <p className='text-sm font-bold'>Date</p>
            <div className='flex items-center gap-3 md:gap-1'>
              <p className='text-sm font-bold text-nowrap'>From :</p>
              <div className='w-[100%] md:w-[75%]'>
                <DatePicker
                  selected={selectedFrom}
                  onChange={(date) => setSelectedFrom(date)}
                  className="outline-none w-[100%] border bg-transparent text-sm p-1 rounded-md"
                  showPopperArrow={false}
                  dateFormat="yyyy-MM-dd"
                  shouldCloseOnSelect={true}
                />
              </div>
            </div>
            <div className='flex items-center gap-3 md:gap-1'>
              <p className='text-sm font-bold text-nowrap'>To :</p>
              <div className='w-[100%] md:w-[75%]'>
                <DatePicker
                  selected={selectedTo}
                  onChange={(date) => setSelectedTo(date)}
                  className="outline-none w-[100%] border bg-transparent text-sm p-1 rounded-md"
                  showPopperArrow={false}
                  dateFormat="yyyy-MM-dd"
                  shouldCloseOnSelect={true}
                />
              </div>
            </div>
          </div>
          <div className='flex md:grid gap-1 m-auto'>
            <div className='flex items-center gap-1 text-sm text-nowrap'>
              <p className='font-bold'>Memeber ID: </p>
              <input className='w-[50%] border rounded-md bg-transparent py-1'></input>
            </div>
            <div>
              <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Submit</button>
            </div>
          </div>
          <div className='flex md:grid gap-1 m-auto items-center justify-center'>
            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
            <div className='w-[25%] md:w-[100%]'>
              <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
            </div>
          </div>
          <div className=' text-sm font-bold m-auto'>
            Page of 1 of 0
          </div>
        </div>
      </div>
      {isChangeName ? (
        <div className=' rounded-lg mt-5 border pb-3'>
          <div className='border-b px-5 py-3'>
            <p className='text-lg font-bold'>Customer Information</p>
          </div>
          <div className='px-5 py-3'>
            <div className='lg:w-[80%] flex flex-col gap-5 mx-auto'>
              {['custID', 'custUserName', 'loginUserName', 'entryDate'].map((field, index) => (
                <div key={index} className='w-[100%] flex flex-col md:flex-row items-center gap-5'>
                  <p className='text-end w-[30%] font-bold'>
                    {field === 'custID' ? 'CustID' :
                      field === 'custUserName' ? 'Cust UserName' :
                        field === 'loginUserName' ? 'Login UserName' :
                          'Entry Date'} :
                  </p>
                  <div className={` w-[50%] rounded-md ${isEditing ? 'border' : 'border py-1 px-3'}`}>
                    {isEditing ? (
                      <input
                        type="text"
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        className='w-[100%] py-1 px-3 outline-none bg-transparent'
                      />
                    ) : (
                      formData[field]
                    )}
                  </div>
                </div>
              ))}
              <div className='w-[100%] flex justify-center'>
                {isEditing ? (
                  <>
                    <button
                      onClick={handleSave}
                      className='px-3 py-1 mx-2 text-sm font-bold bg-green-500 border rounded-full shadow-md'
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      className='px-3 py-1 mx-2 text-sm font-bold border rounded-full shadow-md'
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    onClick={handleEdit}
                    className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                  >
                    Update Username
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=' rounded-lg mt-5 border pb-3'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
            <div className=''>
              <p className='my-auto font-bold'>Showing ZUKY190 Customer Record</p>
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 overflow-hidden overflow-x-auto'>
            <table className='w-full text-center'>
              <thead className='text-sm uppercase font-bold border-b py-3'>
                <tr>
                  <th className='py-3 px-2'>#</th>
                  <th className='px-2'>Process</th>
                  <th className='px-2'>CustID</th>
                  <th className='px-2'>SponserID</th>
                  <th className='px-2'>Cust Name</th>
                  <th className='px-2'>login user name</th>
                  <th className='px-2'>Country</th>
                  <th className='px-2'>Current Rank</th>
                  <th className='px-2'>Registration Date</th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                {currentEntities.map((entity, index) => (
                  <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                    <td className='px-5 md:px-2'>{entity.id}</td>
                    <td className='px-5 md:px-2'><button className='bg-blue-500 rounded-md p-1 text-xs' onClick={handleChangeName}>Change UserName</button></td>
                    <td className='px-5 md:px-2'>{entity.custId}</td>
                    <td className='px-5 md:px-2'>{entity.sponserID}</td>
                    <td className='px-5 md:px-2'>{entity.custname}</td>
                    <td className='px-5 md:px-2'>{entity.loginusername}</td>
                    <td className='px-5 md:px-2'>{entity.country}</td>
                    <td className='px-5 md:px-2'>{entity.currentrank}</td>
                    <td className='px-5 md:px-2'>{entity.registerdate}</td>
                  </tr>
                ))}
                {/* Add empty rows if needed */}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      )}
    </div>
  </div>
  )
}
