import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function WithdrawTransfer() {
    const initialTableEntries = [
        {
            id: "1",
            Type: "Withdrawal",
            fromAmount: "10",
            toAmount: "1000",
            dayName: "All Days",
            date: "10/7/2023 6:38:01 AM",
        },
    ];

    const [tableEntries, setTableEntries] = useState(initialTableEntries);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [editId, setEditId] = useState(null);
    const [formData, setFormData] = useState({ fromAmount: '', toAmount: '', dayName: '' });

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // Handle edit button click
    const handleEditClick = (entity) => {
        setEditId(entity.id);
        setFormData({
            fromAmount: entity.fromAmount,
            toAmount: entity.toAmount,
            dayName: entity.dayName,
        });
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle save button click
    const handleUpdateClick = () => {
        setTableEntries(prevEntries => prevEntries.map(entry => 
            entry.id === editId
                ? { ...entry, fromAmount: formData.fromAmount, toAmount: formData.toAmount, dayName: formData.dayName }
                : entry
        ));
        setEditId(null);
    };

    // Handle cancel button click
    const handleCancelClick = () => {
        setEditId(null);
    };

    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>
                    Set Withdrawal/Transfer Fund Limit
                </h1>
                <div className='rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div>
                            <p className='my-auto font-bold text-lg'>
                                Withdrawal/Transfer Fund Limit Setting
                            </p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>
                                Show no of entity
                                <select 
                                    className='ml-2 outline-none rounded-md bg-transparent border-[1px]' 
                                    onChange={handleDropdownChange} 
                                    value={entitiesPerPage}
                                >
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'></th>
                                    <th className='px-2'>Type</th>
                                    <th className='px-2'>From Amount</th>
                                    <th className='px-2'>To Amount</th>
                                    <th className='px-2'>Day Name</th>
                                    <th className='px-2'>Date & Time</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{entity.id}</td>
                                        <td className='px-5 md:px-2'>
                                            {editId === entity.id ? (
                                                <div className='text-sm flex gap-1'>
                                                    <button className='bg-green-500 px-3 py-1 rounded-md' onClick={handleUpdateClick}>Update</button>
                                                    <button className='bg-red-500 px-3 py-1  rounded-md ' onClick={handleCancelClick}>Cancel</button>
                                                </div>
                                            ) : (
                                                <button className='bg-blue-500 px-3 py-1 rounded-md' onClick={() => handleEditClick(entity)}>Edit</button>
                                            )}
                                        </td>
                                        <td className='px-5 md:px-2'>{entity.Type}</td> 
                                        <td className='px-5 md:px-2'>
                                            {editId === entity.id ? (
                                                <input 
                                                    type='text' 
                                                    name='fromAmount' 
                                                    value={formData.fromAmount} 
                                                    onChange={handleChange}
                                                    className='border w-[75%] py-1 outline-none bg-transparent rounded-md px-2'
                                                />
                                            ) : (
                                                entity.fromAmount
                                            )}
                                        </td>
                                        <td className='px-5 md:px-2'>
                                            {editId === entity.id ? (
                                                <input 
                                                    type='text' 
                                                    name='toAmount' 
                                                    value={formData.toAmount} 
                                                    onChange={handleChange}
                                                    className='border w-[75%] py-1 outline-none bg-transparent rounded-md px-2'
                                                />
                                            ) : (
                                                entity.toAmount
                                            )}
                                        </td>                                  
                                        <td className='px-5 md:px-2'>
                                            {editId === entity.id ? (
                                                <select 
                                                    name='dayName' 
                                                    value={formData.dayName} 
                                                    onChange={handleChange}
                                                    className='border py-1 outline-none bg-transparent rounded-md px-2'
                                                >
                                                    <option className='text-black' value="All Days">All Days</option>
                                                    <option className='text-black' value="Monday">Monday</option>
                                                    <option className='text-black' value="Tuesday">Tuesday</option>
                                                    <option className='text-black' value="Wednesday">Wednesday</option>
                                                    <option className='text-black' value="Thursday">Thursday</option>
                                                    <option className='text-black' value="Friday">Friday</option>
                                                    <option className='text-black' value="Saturday">Saturday</option>
                                                    <option className='text-black' value="Sunday">Sunday</option>
                                                </select>
                                            ) : (
                                                entity.dayName
                                            )}
                                        </td>
                                        <td className='px-5 md:px-2 text-nowrap'>{entity.date}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
