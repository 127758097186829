import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function DescribeWallet() {
    const tableEntries = [
        {
            id: "1",
            trID: "9357729	",
            custID: "708944",
            custname: "Zuky190",
            date: "8/19/2024 7:06:31 PM",
            email: "zuky190@gmail.com",
            wallettype: "DEPOSIT",
            type: "dfd9eaa3bf93ea264706991a91343df6916f5eeee6acfa93a9704f69789a0a71",
        },


    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const openImageInNewTab = (entity) => {
        window.open(entity.doc, '_blank');
    };

    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const [selectedFrom, setSelectedFrom] = useState(null);
    const [selectedTo, setSelectedTo] = useState(null);

    const [selectedOption, setSelectedOption] = useState('Fund Credited');

    const handleChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Describe Wallet Detail</h1>
                <div className=' rounded-lg mt-5 border '>
                    <div className='px-5 py-3 border-b  font-bold text-lg'>
                        <p>Filter Your Search</p>
                    </div>
                    <div className='p-3 grid md:grid-cols-3 lg:grid-cols-6 gap-3 lg:gap-5'>
                        <div className=' text-sm font-bold m-auto'>
                            Total Record : 362
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center'>
                            <p className='text-sm font-bold'>Select Wallet : </p>
                            <select className='bg-transparent border p-1 md:px-3 md:py-1 rounded-md outline-none'>
                                <option className='text-black'>DEPOSIT</option>
                                <option className='text-black'>INCOME</option>
                            </select>
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center'>
                            <p className='text-sm font-bold'>Date</p>
                            <div className='flex items-center gap-3 md:gap-1'>
                                <p className='text-sm font-bold text-nowrap'>From :</p>
                                <div className='w-[100%] md:w-[75%]'>
                                    <DatePicker
                                        selected={selectedFrom}
                                        onChange={(date) => setSelectedFrom(date)}
                                        className="outline-none w-[100%] border bg-transparent text-sm p-1 rounded-md"
                                        showPopperArrow={false}
                                        dateFormat="yyyy-MM-dd"
                                        shouldCloseOnSelect={true}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center gap-3 md:gap-1'>
                                <p className='text-sm font-bold text-nowrap'>To :</p>
                                <div className='w-[100%] md:w-[75%]'>
                                    <DatePicker
                                        selected={selectedTo}
                                        onChange={(date) => setSelectedTo(date)}
                                        className="outline-none w-[100%] border bg-transparent text-sm p-1 rounded-md"
                                        showPopperArrow={false}
                                        dateFormat="yyyy-MM-dd"
                                        shouldCloseOnSelect={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex md:grid gap-1 m-auto'>
                            <div className='flex md:grid items-center gap-3 md:gap-1'>
                                <p className='text-sm font-bold'>Description</p>
                                <select
                                    className='bg-transparent border text-center text-[13px] p-1 rounded-md outline-none'
                                    value={selectedOption}
                                    onChange={handleChange}
                                >
                                    <option className='text-black'>Fund Credited</option>
                                    <option className='text-black'>Fund Debited</option>
                                    <option className='text-black'>Packages</option>
                                    <option className='text-black'>Amount</option>
                                    <option className='text-black'>Deposit</option>
                                    <option className='text-black'>Withdraw</option>
                                </select>
                            </div>
                            <div>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Submit</button>
                            </div>
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
                            <div className='w-[25%] md:w-[100%]'>
                                <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                            </div>
                        </div>
                        <div className=' text-sm font-bold m-auto'>
                            Page of 1 of 0
                        </div>
                    </div>
                </div>
                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div className=''>
                            <p className='my-auto font-bold text-lg'>Showing Transactions Description :  {selectedOption}</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden duration-500 hover:overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'>TrID</th>
                                    <th className='px-2'>Date</th>
                                    <th className='px-2'>CustID</th>
                                    <th className='px-2'>Cust_UserName</th>
                                    <th className='px-2'>Email</th>
                                    <th className='px-2'>Wallet Type</th>
                                    <th className='px-2'>type</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-[13px] text-center rounded-md'>
                                        <td className='px-5 md:px-2'>{entity.id}</td>
                                        <td className='px-5 md:px-2'>{entity.trID}</td>
                                        <td className='px-5 md:px-2'>{entity.date}</td>
                                        <td className='px-5 md:px-2'>{entity.custID}</td>
                                        <td className='px-5 md:px-2'>{entity.custname}</td>
                                        <td className='px-5 md:px-2'>{entity.email}</td>
                                        <td className='px-5 md:px-2'>{entity.wallettype}</td>
                                        <td className='px-5 md:px-2'>{entity.type}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
