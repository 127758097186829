import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faWallet, faUser, faCube, faAddressCard, faChartColumn, faLifeRing, faHand, faRightToBracket, faDollar, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import DashboardHeader from './DashboardHeader';
import { Toaster } from 'react-hot-toast';
import GotoDashboard from './GotoDashboard';
import WalletDetails from './Wallet/WalletDetails';
import DescribeWallet from './Wallet/DescribeWallet';
import CreditWallet from './Wallet/CreditWallet';
import DebitWallet from './Wallet/DebitWallet';
import WithdrawTransfer from './Wallet/WithdrawTransfer';
import Customerinfo from './Customer/Customerinfo';
import Changeusername from './Customer/Changeusername';
import Searchuser from './Customer/Searchuser';
import Kyc from './Customer/Kyc';
import AllMemeberReport from './Customer/AllMemberReport';
import AdminLogin from './ActivityLog/AdminLogin';
import UserActivity from './ActivityLog/UserActivity';
import UserLogin from './ActivityLog/UserLogin';
import Suspensionid from './Configration/Suspensionid';
import BlockId from './Configration/BlockId';
import Configpage from './Configration/Configpage';
import Alltickets from './Support/Alltickets';
import Openticket from './Support/Openticket';
import ClosedTicket from './Support/ClosedTicket';
import Withdrawonce from './Withdraw/Withdrawonce';
import Withdrawsummary from './Withdraw/Withdrawsummary';
import Profile from './Profile/Profile';
import ChangePassword from './Profile/ChangePassword';
import ChangeReward from './ChangeRewards';


export default function MyDashboard() {
    const [selectedTab, setSelectedTab] = useState('Dashboard');
    const [activeTab, setActiveTab] = useState('Dashboard');
    const [activeSubTab, setActiveSubTab] = useState(null); // Track sub-tab state
    const [active, setActive] = useState('div1'); // Main tab content visibility
    const [Wallet, setWallet] = useState(false); // Control dropdown visibility
    const [Customer, setCustomer] = useState(false); // Control dropdown visibility
    const [Activity, setActivity] = useState(false); // Control dropdown visibility
    const [Configration, setConfigration] = useState(false); // Control dropdown visibility
    const [support, setsupport] = useState(false); // Control dropdown visibility
    const [admin, setadmin] = useState(false); // Control dropdown visibility
    const [withdraw, setwithdraw] = useState(false); // Control dropdown visibility
    const [Investment, setInvestment] = useState(false); // Control dropdown visibility
    const [profile, setprofile] = useState(false); // Control dropdown visibility

    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar);
    };

    const handleClickOutside = (event) => {
        if (
            (isOpenNavbar && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setOpenNavbar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenNavbar]);


    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setActiveTab(tab === activeTab ? null : tab);

        // Close the Wallet dropdown if any other tab is clicked
        if (tab !== 'Wallet' || 'Customer' || 'Activity' || 'Configration' || 'Support' || 'Admin' || 'Withdraw' || 'Investment' || 'Profile') {
            setWallet(false);
            setCustomer(false);
            setActivity(false);
            setConfigration(false);
            setsupport(false);
            setadmin(false);
            setwithdraw(false);
            setInvestment(false);
            setprofile(false);
        }

        switch (tab) {
            case 'Customer':
                handleAnchorClick('div2'); // Display 'Wallet' main tab content
                break;
            case 'Wallet':
                handleAnchorClick('div3');
                break;
            case 'Activity':
                handleAnchorClick('div4');
                break;
            case 'Configration':
                handleAnchorClick('div5');
                break;
            case 'Support':
                handleAnchorClick('div6');
                break;
            case 'Admin':
                handleAnchorClick('div7');
                break;
            case 'Withdraw':
                handleAnchorClick('div8');
                break;
            case 'Investment':
                handleAnchorClick('div9');
                break;
            case 'Profile':
                handleAnchorClick('div10');
                break;
            case 'ChangeReward':
                handleAnchorClick('div11');
                break;
            default:
                handleAnchorClick('div1');
        }
    };

    const handleSubTabClick = (subtab) => {
        setActiveSubTab(subtab); // Set the active sub-tab based on the clicked sub-tab
    };

    const handleAnchorClick = (tab) => {
        setActive(tab); // Set main tab content visibility
    };

    const handleWallet = () => {
        setWallet(!Wallet); // Toggle Wallet dropdown visibility
    };

    const handleCustomer = () => {
        setCustomer(!Customer); // Toggle Subcribe dropdown visibility
    };

    const handleActivity = () => {
        setActivity(!Activity); // Toggle team dropdown visibility
    };

    const handleConfigration = () => {
        setConfigration(!Configration); // Toggle team dropdown visibility
    };

    const handleSupport = () => {
        setsupport(!support);
    };

    const handleAdmin = () => {
        setadmin(!admin); // Toggle team dropdown visibility
    };

    const handleWithdraw = () => {
        setwithdraw(!withdraw); // Toggle team dropdown visibility
    };

    const handleInvestment = () => {
        setInvestment(!Investment); // Toggle team dropdown visibility
    };

    const handleProfile = () => {
        setprofile(!profile); // Toggle team dropdown visibility
    };

    const iconMap = {
        'cube': faCube,
        'user': faUser,
        'dolar': faDollarSign,
        'activity': faRightToBracket,
        'hand': faHand,
        'support': faLifeRing,
        'graph': faChartColumn,
        'id': faAddressCard,
    };

    const TabButton = ({ label, tab, icon, onClick }) => (
        <button
            className={`flex justify-center gap-3 w-[80%] md:w-[100%] lg:w-[90%] 2xl:w-[80%] mx-auto uppercase font-bold rounded-xl py-2.5 duration-500
                ${tab === activeTab ? 'bg-gradient-to-r from-[#F23985] via-[#FF99BD] via-[70%] to-[#F23985]' : ''}`}
            onClick={onClick}
        >
            <div className='relative w-[10%]'>
                <FontAwesomeIcon icon={icon} className='text-lg' />
            </div>
            <span className="w-[75%] md:w-[60%] text-start">{label}</span>
        </button>
    );

    const SubTabButton = ({ label, onClick }) => (
        <button
            className={`flex justify-center gap-3 duration-500 font-bold rounded-xl py-1 text-[13px] 2xl:text-sm 
                ${label === activeSubTab ? 'text-pink-600' : ''}`}
            onClick={onClick}
        >
            <span className=" text-center">{label}</span>
        </button>
    );

    return (
        <div>
            <div className="fixed inset-0 z-0 ">
                <img src="/assets/NewMBCbg.png" className="h-full w-full object-cover object-top"></img>
            </div>
            <div className="relative z-10 text-white font-Artemus">
                <DashboardHeader selectedTab={selectedTab} />
                <Toaster />
                <div className='md:flex gap-5 mt-20 md:mt-[5%] lg:mt-[4%] 2xl:mt-[3%]'>
                    <div className="hidden md:block w-[25%] lg:w-[18%] hover:overflow-y-auto">
                        <nav className='mt-[20%] 2xl:mt-[30%]'>
                            <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => handleTabClick('Dashboard')} />
                            <TabButton label='Customer' tab='Customer' icon={iconMap['user']} onClick={() => { handleTabClick('Customer'); handleCustomer(); handleSubTabClick('Customer Information Export') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Customer ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Customer Information Export' onClick={() => { handleSubTabClick('Customer Information Export') }} />
                                <SubTabButton label='Change UserName' onClick={() => handleSubTabClick('Change UserName')} />
                                <SubTabButton label='Search Member' onClick={() => handleSubTabClick('Search Member')} />
                                <SubTabButton label='KYC Approved' onClick={() => handleSubTabClick('KYC Approved')} />
                                <SubTabButton label='All Member Report' onClick={() => handleSubTabClick('All Member Report')} />
                            </div>
                            <TabButton label='Wallet' tab='Wallet' icon={iconMap['dolar']} onClick={() => { handleTabClick('Wallet'); handleWallet(); handleSubTabClick('Wallet Details') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[90%] 2xl:w-[80%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Wallet ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Wallet Details' onClick={() => { handleSubTabClick('Wallet Details') }} />
                                <SubTabButton label='Describe Wallet Detail' onClick={() => handleSubTabClick('Describe Wallet Detail')} />
                                <SubTabButton label='Credit Wallet' onClick={() => handleSubTabClick('Credit Wallet')} />
                                <SubTabButton label='Debit Wallet' onClick={() => handleSubTabClick('Debit Wallet')} />
                                <SubTabButton label='Set Withdrawal/Transfer Limit' onClick={() => handleSubTabClick('Set Withdrawal/Transfer Limit')} />
                            </div>
                            <TabButton label='Activity' tab='Activity' icon={iconMap['activity']} onClick={() => { handleTabClick('Activity'); handleActivity(); handleSubTabClick('Admin Login Details') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Activity ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Admin Login Details' onClick={() => { handleSubTabClick('Admin Login Details') }} />
                                <SubTabButton label='Admin Activity Log Detail' onClick={() => handleSubTabClick('Admin Activity Log Detail')} />
                                <SubTabButton label='User Activity Log Detail' onClick={() => { handleSubTabClick('User Activity Log Detail') }} />
                                <SubTabButton label='User LogIn Detail' onClick={() => { handleSubTabClick('User LogIn Detail') }} />
                            </div>
                            <TabButton label='Configration' tab='Configration' icon={iconMap['hand']} onClick={() => { handleTabClick('Configration'); handleConfigration(); handleSubTabClick('Suspended Id') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Configration ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Suspended Id' onClick={() => { handleSubTabClick('Suspended Id') }} />
                                <SubTabButton label='Config Pages' onClick={() => handleSubTabClick('Config Pages')} />
                                <SubTabButton label='Block ID' onClick={() => handleSubTabClick('Block ID')} />
                            </div>
                            <TabButton label='Support' tab='Support' icon={iconMap['support']} onClick={() => { handleTabClick('Support'); handleSupport(); handleSubTabClick('All Ticket') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${support ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='All Ticket' onClick={() => { handleSubTabClick('All Ticket') }} />
                                <SubTabButton label='Open Ticket' onClick={() => handleSubTabClick('Open Ticket')} />
                                <SubTabButton label='Closed Ticket' onClick={() => handleSubTabClick('Closed Ticket')} />
                            </div>
                            <TabButton label='Admin' tab='Admin' icon={iconMap['graph']} onClick={() => { handleTabClick('Admin'); handleAdmin(); handleSubTabClick('Admin Popup Master') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${admin ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Admin Popup Master' onClick={() => { handleSubTabClick('Admin Popup Master') }} />
                                <SubTabButton label='Manually Level Active' onClick={() => handleSubTabClick('Manually Level Active')} />
                                <SubTabButton label='Manually Level Active Report' onClick={() => handleSubTabClick('Manually Level Active Report')} />
                            </div>
                            <TabButton label='Withdraw' tab='Withdraw' icon={iconMap['graph']} onClick={() => { handleTabClick('Withdraw'); handleWithdraw(); handleSubTabClick('Withdrawal All At Once') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${withdraw ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Withdrawal All At Once' onClick={() => { handleSubTabClick('Withdrawal All At Once') }} />
                                <SubTabButton label='Withdrawal Summary' onClick={() => handleSubTabClick('Withdrawal Summary')} />
                            </div>
                            <TabButton label='Investment' tab='Investment' icon={iconMap['graph']} onClick={() => { handleTabClick('Investment'); handleInvestment(); handleSubTabClick('Investment History') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Investment ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Investment History' onClick={() => { handleSubTabClick('Investment History') }} />
                                <SubTabButton label='Investment Downline History' onClick={() => handleSubTabClick('Investment Downline History')} />
                                <SubTabButton label='Investment Delete' onClick={() => { handleSubTabClick('Investment Delete') }} />
                                <SubTabButton label='Investment Delete History' onClick={() => handleSubTabClick('Investment Delete History')} />
                                <SubTabButton label='Investment Active / Deactive' onClick={() => handleSubTabClick('Investment Active / Deactive')} />
                            </div>
                            <TabButton label='Profile' tab='Profile' icon={iconMap['id']} onClick={() => { handleTabClick('Profile'); handleProfile(); handleSubTabClick('Profile') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${profile ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Profile' onClick={() => { handleSubTabClick('Profile') }} />
                                <SubTabButton label='Change Password' onClick={() => handleSubTabClick('Change Password')} />
                            </div>
                            <TabButton label='Change Reward' tab='ChangeReward' icon={iconMap['graph']} onClick={() => handleTabClick('ChangeReward')} />
                        </nav>
                    </div>

                    <div className='block md:hidden p-5'>
                        <button onClick={handleOpenNavbar} ref={buttonRef}>
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                            </svg>
                        </button>
                        {isOpenNavbar && (
                            <nav className='w-[80%] z-10 py-5 absolute border rounded-3xl bg-[#872245] text-white grid content-around ' data-aos="fade-right" data-aos-duration="1000" ref={dropdownRef} >
                                <div>
                                    <div>
                                        <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => handleTabClick('Dashboard')} />
                                        <TabButton label='Customer' tab='Customer' icon={iconMap['user']} onClick={() => { handleTabClick('Customer'); handleCustomer(); handleSubTabClick('Customer Information Export') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Customer ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Customer Information Export' onClick={() => { handleSubTabClick('Customer Information Export') }} />
                                            <SubTabButton label='Change UserName' onClick={() => handleSubTabClick('Change UserName')} />
                                            <SubTabButton label='Search Member' onClick={() => handleSubTabClick('Search Member')} />
                                            <SubTabButton label='KYC Approved' onClick={() => handleSubTabClick('KYC Approved')} />
                                            <SubTabButton label='All Member Report' onClick={() => handleSubTabClick('All Member Report')} />
                                        </div>
                                        <TabButton label='Wallet' tab='Wallet' icon={iconMap['dolar']} onClick={() => { handleTabClick('Wallet'); handleWallet(); handleSubTabClick('Wallet Details') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[90%] 2xl:w-[80%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Wallet ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Wallet Details' onClick={() => { handleSubTabClick('Wallet Details') }} />
                                            <SubTabButton label='Describe Wallet Detail' onClick={() => handleSubTabClick('Describe Wallet Detail')} />
                                            <SubTabButton label='Credit Wallet' onClick={() => handleSubTabClick('Credit Wallet')} />
                                            <SubTabButton label='Debit Wallet' onClick={() => handleSubTabClick('Debit Wallet')} />
                                            <SubTabButton label='Set Withdrawal/Transfer Limit' onClick={() => handleSubTabClick('Set Withdrawal/Transfer Limit')} />
                                        </div>
                                        <TabButton label='Activity' tab='Activity' icon={iconMap['activity']} onClick={() => { handleTabClick('Activity'); handleActivity(); handleSubTabClick('Admin Login Details') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Activity ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Admin Login Details' onClick={() => { handleSubTabClick('Admin Login Details') }} />
                                            <SubTabButton label='Admin Activity Log Detail' onClick={() => handleSubTabClick('Admin Activity Log Detail')} />
                                            <SubTabButton label='User Activity Log Detail' onClick={() => { handleSubTabClick('User Activity Log Detail') }} />
                                            <SubTabButton label='User LogIn Detail' onClick={() => { handleSubTabClick('User LogIn Detail') }} />
                                        </div>
                                        <TabButton label='Configration' tab='Configration' icon={iconMap['hand']} onClick={() => { handleTabClick('Configration'); handleConfigration(); handleSubTabClick('Suspended Id') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Configration ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Suspended Id' onClick={() => { handleSubTabClick('Suspended Id') }} />
                                            <SubTabButton label='Config Pages' onClick={() => handleSubTabClick('Config Pages')} />
                                            <SubTabButton label='Block ID' onClick={() => handleSubTabClick('Block ID')} />
                                        </div>
                                        <TabButton label='Support' tab='Support' icon={iconMap['support']} onClick={() => { handleTabClick('Support'); handleSupport(); handleSubTabClick('All Ticket') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${support ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='All Ticket' onClick={() => { handleSubTabClick('All Ticket') }} />
                                            <SubTabButton label='Open Ticket' onClick={() => handleSubTabClick('Open Ticket')} />
                                            <SubTabButton label='Closed Ticket' onClick={() => handleSubTabClick('Closed Ticket')} />
                                        </div>
                                        <TabButton label='Admin' tab='Admin' icon={iconMap['graph']} onClick={() => { handleTabClick('Admin'); handleAdmin(); handleSubTabClick('Admin Popup Master') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${admin ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Admin Popup Master' onClick={() => { handleSubTabClick('Admin Popup Master') }} />
                                            <SubTabButton label='Manually Level Active' onClick={() => handleSubTabClick('Manually Level Active')} />
                                            <SubTabButton label='Manually Level Active Report' onClick={() => handleSubTabClick('Manually Level Active Report')} />
                                        </div>
                                        <TabButton label='Withdraw' tab='Withdraw' icon={iconMap['graph']} onClick={() => { handleTabClick('Withdraw'); handleWithdraw(); handleSubTabClick('Withdrawal All At Once') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${withdraw ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Withdrawal All At Once' onClick={() => { handleSubTabClick('Withdrawal All At Once') }} />
                                            <SubTabButton label='Withdrawal Summary' onClick={() => handleSubTabClick('Withdrawal Summary')} />
                                        </div>
                                        <TabButton label='Investment' tab='Investment' icon={iconMap['graph']} onClick={() => { handleTabClick('Investment'); handleInvestment(); handleSubTabClick('Investment History') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Investment ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Investment History' onClick={() => { handleSubTabClick('Investment History') }} />
                                            <SubTabButton label='Investment Downline History' onClick={() => handleSubTabClick('Investment Downline History')} />
                                            <SubTabButton label='Investment Delete' onClick={() => { handleSubTabClick('Investment Delete') }} />
                                            <SubTabButton label='Investment Delete History' onClick={() => handleSubTabClick('Investment Delete History')} />
                                            <SubTabButton label='Investment Active / Deactive' onClick={() => handleSubTabClick('Investment Active / Deactive')} />
                                        </div>
                                        <TabButton label='Profile' tab='Profile' icon={iconMap['id']} onClick={() => { handleTabClick('Profile'); handleProfile(); handleSubTabClick('Profile') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                            ${profile ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Profile' onClick={() => { handleSubTabClick('Profile') }} />
                                            <SubTabButton label='Change Password' onClick={() => handleSubTabClick('Change Password')} />
                                        </div>
                                        <TabButton label='Change Reward' tab='ChangeReward' icon={iconMap['cube']} onClick={() => handleTabClick('ChangeReward')} />

                                    </div>
                                    <div className='flex justify-center py-3 text-lg'><button className='font-Artemus font-bold uppercase'>Logout</button></div>
                                </div>
                            </nav>
                        )}
                    </div>


                    <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        <GotoDashboard />
                    </div>
                    <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        {(() => {
                            switch (activeSubTab) {
                                case 'Customer Information Export':
                                    return <Customerinfo />;
                                case 'Change UserName':
                                    return <Changeusername />;
                                case 'Search Member':
                                    return <Searchuser />;
                                case 'KYC Approved':
                                    return <Kyc />;
                                case 'All Member Report':
                                    return <AllMemeberReport />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        {(() => {
                            switch (activeSubTab) {
                                case 'Wallet Details':
                                    return <WalletDetails />;
                                case 'Describe Wallet Detail':
                                    return <DescribeWallet />;
                                case 'Credit Wallet':
                                    return <CreditWallet />;
                                case 'Debit Wallet':
                                    return <DebitWallet />;
                                case 'Set Withdrawal/Transfer Limit':
                                    return <WithdrawTransfer />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div4'
                        style={{ display: active === 'div4' ? 'block' : 'none' }}
                        className='md:w-[75%] lg:w-[82%] md:absolute right-0'
                    >
                        {(() => {
                            switch (activeSubTab) {
                                case 'Admin Login Details':
                                    return <AdminLogin />;
                                case 'Admin Activity Log Detail':
                                    return;
                                case 'User Activity Log Detail':
                                    return <UserActivity />;
                                case 'User LogIn Detail':
                                    return <UserLogin />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div5' style={{ display: active === 'div5' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        {(() => {
                            switch (activeSubTab) {
                                case 'Suspended Id':
                                    return <Suspensionid />;
                                case 'Config Pages':
                                    return <Configpage />;
                                case 'Block ID':
                                    return <BlockId />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div6' style={{ display: active === 'div6' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        {(() => {
                            switch (activeSubTab) {
                                case 'All Ticket':
                                    return <Alltickets />;
                                case 'Open Ticket':
                                    return <Openticket />;
                                case 'Closed Ticket':
                                    return <ClosedTicket />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div7' style={{ display: active === 'div7' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                    </div>
                    <div id='div8' style={{ display: active === 'div8' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        {activeSubTab === 'Withdrawal All At Once' ? <Withdrawonce /> : activeSubTab === 'Withdrawal Summary' ? <Withdrawsummary /> : null}
                    </div>
                    <div id='div9' style={{ display: active === 'div9' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                    </div>
                    <div id='div10' style={{ display: active === 'div10' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        {(() => {
                            switch (activeSubTab) {
                                case 'Profile':
                                    return <Profile />;
                                case 'Change Password':
                                    return <ChangePassword />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div11' style={{ display: active === 'div11' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[82%] md:absolute right-0'>
                        <ChangeReward />
                    </div>


                </div>
            </div>
        </div>
    );
}
