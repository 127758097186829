import React from 'react'

export default function Skilleareumai() {
  return (
    <div className='relative h-[88vh] mt-5'>
      <div className='absolute inset-0 model-overlay h-full bg-black/20 backdrop-blur-md flex items-center justify-center'>
        <div className='md:w-[60%] text-center  p-5'>
          <h1 className='font-Artemus text-7xl uppercase font-bold bg-gradient-to-r from-[#F23984] to-[#FB9136] bg-clip-text text-transparent'>Coming soon</h1>
          <p className='text-white text-wrap font-semibold font-poppins'>Unlock Unlimited Opportunities with Our Revolutionary MLM Platform!
 Stay connected and be ready to take the first step towards building your wealth.

</p>
        </div>
      </div>
    </div>
  )
}
