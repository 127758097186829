import React, { useEffect, useState } from 'react';
import '../../Style/App.css';
import { faArrowLeft, faArrowRight, faCheck, faCheckCircle, faStar, faStarOfLife, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Marquee from 'react-fast-marquee';
import Chart from './Chart';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import toast from "react-hot-toast";
import { SERVER_URL } from "../../../config";
import Faq from './Faq';
import Header from '../../Layout/Header2';

export default function Home2() {

    const [activePoint, setActivePoint] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setActivePoint((prev) => (prev < 3 ? prev + 1 : 1));
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute z-10 top-1/2 transform -translate-y-1/2 -left-5 w-10 h-10 rounded-full bg-black cursor-pointer duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736]"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faArrowLeft} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute top-1/2 transform -translate-y-1/2 -right-5 w-10 h-10 rounded-full bg-black cursor-pointer  duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736]"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faArrowRight} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]+$/;

        if (!formData.firstName.trim()) {
            toast.error('First name is required.');
            return;
        }

        if (!formData.lastName.trim()) {
            toast.error('Last name is required.');
            return;
        }

        if (!formData.email.trim() || !emailRegex.test(formData.email)) {
            toast.error('A valid email address is required.');
            return;
        }

        if (!formData.phoneNumber.trim() || !phoneRegex.test(formData.phoneNumber)) {
            toast.error('Mobile number should contain only numbers.');
            return;
        }

        if (!formData.subject.trim()) {
            toast.error('Subject is required.');
            return;
        }

        if (!formData.message.trim()) {
            toast.error('Message is required.');
            return;
        }

        try {
            console.log("Sending form data:", formData);
            const response = await fetch(`${SERVER_URL}/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            console.log("Server response:", result);

            if (response.ok) {
                toast.success('We will contact you soon!');
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    message: ''
                });
            } else {
                toast.error(result.error || 'Error sending message.');
            }
        } catch (error) {
            console.error("Submit error:", error);
            toast.error('Error sending message.');
        }
    };


    return (
        <div className='font-poppins overflow-hidden'>
            <Header/>
            <div className='sec-1 lg:min-h-screen pt-28 pb-10 flex items-center'>
                <div className='flex flex-col lg:flex-row gap-5 lg:gap-0 w-[90%] mx-auto items-center'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src='/assets/NewHome1.png' className='w-[75%] mx-auto' data-aos='flip-left'></img>
                    </div>
                    <div className='w-[100%] lg:w-[50%]'>
                        <div className='flex relative' data-aos='fade-right'>
                            <svg class="h-8 w-9 lg:absolute -left-[10%] -top-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H1" />
                            </svg>
                            <h1 className='text-uppercase font-Artemus font-bold text-2xl text-shadow-custom'>About us</h1>
                        </div>
                        <p className='text-xl md:text-3xl font-semibold tracking-wider mt-3' data-aos='fade-up' data-aos-duration='1000'>Who We Are - A Blockchain & <br /> AI-Driven <span className='text-gradient'>Membership Club</span></p>
                        <p className='mt-7 font-medium'data-aos='fade-up' data-aos-duration='1250'>At My Business Club, we empower entrepreneurs through a community-centric model that leverages education, blockchain technology, and an innovative referral program. Our goal is to help you achieve financial independence and success through collaborative efforts and shared opportunities.</p>
                        <div className='mt-7 flex flex-wrap gap-2 md:gap-3 lg:gap-5'>
                            <div className='bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full py-1 px-3 text-white' data-aos='fade-right' data-aos-duration='1000'>
                                Blockchain
                            </div>
                            <div className='bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full py-1 px-3 text-white' data-aos='fade-right' data-aos-duration='1250'>
                                Artificial Intelligence - AI
                            </div>
                            <div className='bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full py-1 px-3 text-white' data-aos='fade-right' data-aos-duration='1500'>
                                NFT’S
                            </div>
                            <div className='bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full py-1 px-3 text-white' data-aos='fade-right' data-aos-duration='1750'>
                                Smart Contracts
                            </div>
                            <div className='bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full py-1 px-3 text-white' data-aos='fade-right' data-aos-duration='2000'>
                                5000+ Happy users
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sec-2 py-10 flex items-center'>
                <div className='flex flex-col lg:flex-row gap-5 lg:gap-0 w-[90%] mx-auto relative'>
                    <div className=''>
                        <div className='flex gap-3 relative items-center' data-aos='fade-right' data-aos-duration='1000'>
                            <svg class="h-8 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H1" />
                            </svg>
                            <h1 className='text-uppercase font-Artemus font-bold text-2xl text-shadow-custom'>Our Vission</h1>
                        </div>
                        <div className="flex flex-col p-6 ">
                            <div className="flex items-start space-x-4">
                                <div className="flex flex-col items-center pt-2">
                                    <div className=' bg-gray-300 rounded-full p-1'>
                                        <div className={`border h-4 w-4 m-auto rounded-full duration-500 ${activePoint === 1 ? 'bg-gradient-to-r from-[#F23985] to-[#FB9236]' : 'bg-white'}`}></div>
                                    </div>
                                    <div className="h-12 md:h-20 border-l-2 border border-gray-300"></div>
                                </div>

                                <div className='-translate-y-3 md:-translate-y-5'>
                                    <p className='text-xl md:text-3xl font-semibold tracking-wide mt-3' data-aos='fade-right' data-aos-duration='1250'>
                                        <span className='hidden md:block'>Decentralized AI And The<br /><span className='text-gradient'> Tokenized Learning</span></span>
                                        <span className='block md:hidden'>Decentralized AI And The<span className='text-gradient'> Tokenized Learning</span></span>
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start space-x-4">
                                <div className="flex flex-col items-center">
                                    <div className=' bg-gray-300 rounded-full p-1'>
                                        <div className={`border h-4 w-4 m-auto rounded-full duration-500 ${activePoint === 2 ? 'bg-gradient-to-r from-[#F23985] to-[#FB9236]' : 'bg-white'}`}></div>
                                    </div>
                                    <div className="h-28 md:h-20 border-l-2 border border-gray-300"></div>
                                </div>
                                <div className='-translate-y-2 w-[100%] md:w-[75%] text-medium'>
                                    <p data-aos='fade-right' data-aos-duration='1500'>
                                        Imagine a place where small businesses collaborate to achieve big deals, accessing opportunities often reserved for large institutions.
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start space-x-4">
                                <div className="flex flex-col items-center">
                                    <div className=' bg-gray-300 rounded-full p-1'>
                                        <div className={`border h-4 w-4 m-auto rounded-full duration-500 ${activePoint === 3 ? 'bg-gradient-to-r from-[#F23985] to-[#FB9236]' : 'bg-white'}`}></div>
                                    </div>
                                </div>
                                <div className='-translate-y-2 w-[100%] md:w-[85%] text-medium'>
                                    <p data-aos='fade-right' data-aos-duration='1750'>
                                        Community is everything; no business can succeed without customers. The bigger the community, the more profits we can make together.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='' data-aos='fade-down' data-aos-duration='1000'>
                        <img src='/assets/Scroll.png' className='absolute -top-10 md:top-0 right-0 w-20 md:w-48 lg:w-auto'></img>
                    </div>
                </div>
            </div>
            <div className='sec-3 min-h-[90vh] md:min-h-[85vh] lg:min-h-[90vh] bg-black text-white overflow-hidden flex items-center'>
                <div className='flex flex-col lg:flex-row gap-10 w-[90%] mx-auto items-center lg:gap-0 py-10 lg:py-0'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src='/assets/NewHome2.png' className='w-[75%] mx-auto' data-aos='flip-right' data-aos-duration='1000'></img>
                    </div>
                    <div className='w-[100%] lg:w-[50%]'>
                        <div className='flex gap-3 relative items-center' data-aos='fade-right' data-aos-duration='1000'>
                            <svg class="h-8 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H1" />
                            </svg>
                            <h1 className='text-uppercase font-Artemus font-bold text-2xl'>Our Benefits</h1>
                        </div>
                        <p className='text-xl md:text-3xl font-semibold tracking-wide mt-3' data-aos='fade-right' data-aos-duration='1250'>
                            Our Exclusive <span className='text-gradient'> Membership Benefits</span>
                        </p>
                        <ul className='mt-10 flex flex-col gap-3 font-bold '>
                            <li className='flex gap-3' data-aos="fade-left" data-aos-duration="1500"><span className='bg-gradient-to-r from-[#E43875] to-[#F98736]  rounded-full font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheckCircle} className=' p-1 rounded-full' /></span> Exclusive Library of Books</li>
                            <li className='flex gap-3' data-aos="fade-left" data-aos-duration="1750"><span className='bg-gradient-to-r from-[#E43875] to-[#F98736]  rounded-full font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheckCircle} className=' p-1 rounded-full' /></span> Exclusive Podcast series</li>
                            <li className='flex gap-3' data-aos="fade-left" data-aos-duration="2000"><span className='bg-gradient-to-r from-[#E43875] to-[#F98736]  rounded-full font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheckCircle} className=' p-1 rounded-full' /></span> Exclusive Business Meetings</li>
                            <li className='flex gap-3' data-aos="fade-left" data-aos-duration="2250"><span className='bg-gradient-to-r from-[#E43875] to-[#F98736]  rounded-full font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheckCircle} className=' p-1 rounded-full' /></span> Exclusive New Letter</li>
                            <li className='flex gap-3' data-aos="fade-left" data-aos-duration="2500"><span className='bg-gradient-to-r from-[#E43875] to-[#F98736]  rounded-full font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheckCircle} className=' p-1 rounded-full' /></span> Exclusive Travelling Opportunity </li>
                        </ul>
                        <div className='flex justify-center md:justify-start  mt-10'>
                            <div className=' bg-white p-[3px] rounded-full group flex overflow-hidden relative cursor-pointer' data-aos="fade-left" data-aos-duration="2500">
                                <FontAwesomeIcon icon={faArrowRight} className='bg-black  text-[#F98736] rounded-full -translate-x-10 -rotate-45 duration-500 group-hover:translate-x-0 px-[10px] py-2 absolute' />
                                <div className='flex gap-2 duration-500 group-hover:translate-x-10'>
                                    <button className='py-1 px-3 rounded-full font-bold bg-gradient-to-r from-[#E43875] to-[#F98736]'>Join us Now</button>
                                    <FontAwesomeIcon icon={faArrowRight} className='bg-black rounded-full px-[10px] text-[#F98736] py-2 -rotate-45' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Marqee min-h-[10vh] md:min-h-[5vh] lg:min-h-[10vh] flex items-center text-shadow-custom font-semibold text-white bg-gradient-to-r from-[#E43875] to-[#F98736]'>
                <Marquee>
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                </Marquee>
            </div>
            <div className='sec-4 lg:min-h-screen grid content-center overflow-hidden py-10 lg:py-0'>
                <div className='flex flex-col md:flex-row gap-5 lg:gap-0 justify-between items-center w-[90%] mx-auto '>
                    <div>
                        <div className='flex gap-3 relative items-center' data-aos='fade-right' data-aos-duration='1000'>
                            <svg class="h-8 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H1" />
                            </svg>
                            <h1 className='text-uppercase font-Artemus font-bold text-2xl text-shadow-custom'>projects & products</h1>
                        </div>
                        <p className='text-xl md:text-3xl font-semibold tracking-wide mt-3 ml-12' data-aos='fade-right' data-aos-duration='1250'>Our Latest and Futuristic <br /> <span className='text-gradient'> projects & products </span></p>
                    </div>
                    <div>
                        <div className=' bg-gradient-to-r from-[#E43875] to-[#F98736] p-[2px] rounded-full' data-aos='fade-left' data-aos-duration='1000'>
                            <div className=' bg-white text-white p-[3px] cursor-pointer rounded-full my-auto group flex overflow-hidden relative' data-aos="fade-left" data-aos-duration="">
                                <FontAwesomeIcon icon={faArrowRight} className='bg-black rounded-full text-[#F98736] -translate-x-10 duration-500 group-hover:translate-x-0 px-[10px] py-2 absolute' />
                                <div className='flex gap-2 duration-500 group-hover:translate-x-10'>
                                    <button className='py-1 px-3 rounded-full text-sm font-bold bg-gradient-to-r from-[#E43875] to-[#F98736] group-hover:from-[#F98736]  group-hover:to-[#E43875] duration-500'>View All Products</button>
                                    <FontAwesomeIcon icon={faArrowRight} className='bg-black rounded-full text-[#F98736] px-[10px] py-2 ' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid md:grid-cols-3 lg:flex gap-5 lg:gap-0 justify-around w-[90%] mx-auto mt-10'>
                    <div className='w-[100%] lg:w-[30%]'>
                        <img src='/assets/C1.png' className='w-full' data-aos="zoom-in" data-aos-duration="1000"></img>
                        <h1 className='mt-3 text-uppercase font-Artemus font-bold text-xl text-shadow-custom' data-aos='fade-up' data-aos-duration='1250'>Ainstein.Ai</h1>
                        <p className='mt-1' data-aos='fade-up' data-aos-duration='1500'>The world’s first transparent zero-loss DEX arbitrage bot. You are the custodian of your funds in decentralized exchanges, and your account balance will never go down.</p>
                    </div>
                    <div className='w-[100%] lg:w-[30%]'>
                        <img src='/assets/C2.png' className='w-full' data-aos="zoom-in" data-aos-duration="1000"></img>
                        <h1 className='mt-3 text-uppercase font-Artemus font-bold text-xl text-shadow-custom'  data-aos='fade-up' data-aos-duration='1250'>skilleareum.ai</h1>
                        <p className='mt-1'  data-aos='fade-up' data-aos-duration='1500'>The world’s first transparent zero-loss DEX arbitrage bot. You are the custodian of your funds in decentralized exchanges, and your account balance will never go down.</p>
                    </div>
                    <div className='w-[100%] lg:w-[30%]'>
                        <img src='/assets/C3.png' className='w-full' data-aos="zoom-in" data-aos-duration="1000"></img>
                        <h1 className='mt-3 text-uppercase font-Artemus font-bold text-xl text-shadow-custom'  data-aos='fade-up' data-aos-duration='1250'>Arbgpt.ai</h1>
                        <p className='mt-1'  data-aos='fade-up' data-aos-duration='1500'>The world’s first transparent zero-loss DEX arbitrage bot. You are the custodian of your funds in decentralized exchanges, and your account balance will never go down.</p>
                    </div>
                </div>
            </div>
            <div className='sec-5 lg:min-h-[90vh] flex items-center'>
                <div className='w-[90%] mx-auto py-10 lg:py-10'>
                    <div>
                        <div className='flex gap-3 relative items-center'  data-aos='fade-right' data-aos-duration='1000'>
                            <svg class="h-8 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H1" />
                            </svg>
                            <h1 className='text-uppercase font-Artemus font-bold text-2xl text-shadow-custom'>Plans & Pricing</h1>
                        </div>
                        <p className='mt-5 font-medium ml-12' data-aos='fade-up' data-aos-duration='1250'>Whether your time-saving automation needs are large or small, we’re here to help you scale.</p>
                        <div className='planandprice mt-10 flex flex-col md:flex-row justify-around rounded-3xl w-[100%] lg:w-[75%] 2xl:w-[65%] mx-auto bg-gray-100 border shadow-md '  data-aos='fade-up' data-aos-duration='1500'>
                            <div className='py-7 lg:py-10 px-4 lg:px-7 rounded-2xl w-[100%] md:w-1/3 lg:w-[30%] duration-500 group hover:bg-gradient-to-b from-[#F23985] to-[#FB9236] hover:text-white hover:-translate-y-4 hover:shadow-lg'>
                                <h1 className='font-bold text-3xl'>$30<span className='text-base font-normal'>/Year</span></h1>
                                <p className='font-semibold text-xl mt-3'>Basic</p>
                                <p className='mt-1 text-sm h-[40px]'>Unleash the power of MBC.</p>
                                <ul className='text-sm mt-3 grid gap-2'>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Access to Library</li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Podcasts</li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Newsletter </li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Zoom Meetings</li>
                                </ul>
                                <div className='flex justify-center mt-7'>
                                    <button className='px-10 py-1.5 text-sm rounded-full bg-[#DCDCDC] group-hover:bg-white duration-500'>
                                        <p className='duration-500 font-medium text-black group-hover:bg-gradient-to-r from-[#F23985] to-[#FB9236] group-hover:bg-clip-text group-hover:text-transparent group-hover:font-bold'>Choose plan</p>
                                    </button>
                                </div>
                            </div>
                            <div className='py-7 lg:py-10 px-4 lg:px-7 rounded-2xl w-[100%] md:w-1/3 lg:w-[30%] duration-500 group hover:bg-gradient-to-b from-[#F23985] to-[#FB9236] hover:text-white hover:-translate-y-4 hover:shadow-lg'>
                                <h1 className='font-bold text-3xl'>$100<span className='text-base font-normal'>/Year</span></h1>
                                <p className='font-semibold text-xl mt-3'>Premium</p>
                                <p className='mt-1 text-sm'>Advanced tools to take your work to the next level.</p>
                                <ul className='text-sm mt-3 grid gap-2'>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> All Basic Membership </li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Savings on hotels </li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Travel Access </li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Live events </li>
                                </ul>
                                <div className='flex justify-center mt-7'>
                                    <button className='px-10 py-1.5 text-sm rounded-full bg-[#DCDCDC] group-hover:bg-white duration-500'>
                                        <p className='duration-500 font-medium text-black group-hover:bg-gradient-to-r from-[#F23985] to-[#FB9236] group-hover:bg-clip-text group-hover:text-transparent group-hover:font-bold'>Choose plan</p>
                                    </button>
                                </div>
                            </div>
                            <div className='pt-7 lg:pt-10 pb-5 px-4 lg:px-7 rounded-2xl w-[100%] md:w-1/3 lg:w-[30%] duration-500 bg-gradient-to-b from-[#F23985] to-[#FB9236] text-white md:-translate-y-4 shadow-lg relative'>
                                <div className='absolute right-7 top-3 px-3 py-1 rounded-full bg-white duration-500 text-center'>
                                    <p className='duration-500 text-[10px] bg-gradient-to-r from-[#F23985] to-[#FB9236] bg-clip-text text-transparent font-bold'>MOST POPULAR</p>
                                </div>
                                <h1 className='font-bold text-3xl mt-3'>$500<span className='text-base font-normal'>/Year</span></h1>
                                <p className='font-semibold text-xl mt-3'>Elite</p>
                                <p className='mt-1 text-sm'>Automation plus enterprise-grade features.</p>
                                <ul className='text-sm mt-3 grid gap-2'>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Investment opportunity </li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Business Advisory</li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Products Early Access </li>
                                    <li className='flex items-center gap-2'><FontAwesomeIcon icon={faCheck} className='p-1 text-xs bg-gradient-to-r from-[#F23985] to-[#FB9236] rounded-full text-white' /> Personalized Solutions</li>
                                </ul>
                                <div className='flex justify-center mt-7'>
                                    <button className='px-10 py-1.5 text-sm rounded-full bg-white duration-500'>
                                        <p className='duration-500 bg-gradient-to-r from-[#F23985] to-[#FB9236] bg-clip-text text-transparent font-bold'>Choose plan</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Marqee min-h-[10vh] md:min-h-[5vh] lg:min-h-[10vh] flex items-center text-shadow-custom font-semibold text-white bg-gradient-to-r from-[#E43875] to-[#F98736]'>
                <Marquee>
                    <p>Make Profits</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>Community Benefits</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>Invest In Projects</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>Referral Earnings</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>Claim Rewards</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>Invest In Projects</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                    <p>My Business Club</p>
                    <FontAwesomeIcon icon={faStarOfLife} className='text-black mx-5' />
                </Marquee>
            </div>
            <div className='sec-6 lg:min-h-screen flex items-center'>
                <div className='w-[90%] mx-auto py-10 lg:py-10'>
                    <div className='flex gap-3 relative items-center'  data-aos='fade-right' data-aos-duration='1000'>
                        <svg class="h-8 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H1" />
                        </svg>
                        <h1 className='text-uppercase font-Artemus font-bold text-2xl text-shadow-custom'>Leaderboard</h1>
                    </div>
                    <p className='text-xl md:text-3xl font-semibold tracking-wide mt-3 ml-12' data-aos='fade-up' data-aos-duration='1250'>Our Top Leaders and <span className='text-gradient'> Their Earnings </span></p>
                    <div className='flex flex-col md:flex-row gap-10 md:gap-0 '>
                        <div className='md:w-[50%] flex items-center '>
                            <div className='w-[100%] md:translate-x-[20%] lg:translate-x-[20%]'>
                                <div className='w-[100%] border-2 border-black rounded-3xl py-5 lg:py-5 px-5 lg:px-10 font-bold  z-0'  data-aos='fade-left' data-aos-duration='1500'>
                                    <p className='font-bold text-lg'>Earnings Report</p>
                                    <p className='text-2xl font-bold mt-1'>$821.40</p>
                                    <div className='w-[100%] md:w-[80%]'>
                                        <Chart />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='md:w-[50%] flex items-center z-10'><img src='/assets/Leaderboard.png' className='md:w-[80%]' data-aos='fade-left' data-aos-duration="1500"></img></div>
                    </div>
                </div>
            </div>
            <div className="sec-7 lg:min-h-screen font-poppins flex items-center  overflow-hidden">
                <div className="w-[100%] py-10 flex flex-col justify-center items-center ">
                    <div className="w-full flex justify-center items-center gap-10">
                        <div className="w-[50%] flex" data-aos="fade-right" data-aos-duration="1000">
                            <div className="w-[98%] h-1 bg-gradient-to-r from-[#E43875] to-[#F98736] "></div>
                            <div className="w-5 h-5 -translate-x-2 -translate-y-2 rounded-full bg-[#F98736] p-1">
                                <div className="bg-white rounded-full w-full h-full"></div>
                            </div>
                        </div>
                        <div>
                            <p className="text-xl md:text-3xl font-bold text-center tracking-wide" data-aos="zoom-out" data-aos-duration="1000">Testimonials that
                                Speaks to <span className='text-gradient'>Our Results</span></p>
                        </div>
                        <div className="w-[50%] flex" data-aos="fade-left" data-aos-duration="1000">
                            <div className="w-5 h-5 translate-x-2 -translate-y-2 rounded-full bg-[#F98736] p-1">
                                <div className="bg-white rounded-full w-full h-full"></div>
                            </div>
                            <div className="w-[98%] h-1 bg-gradient-to-r from-[#E43875] to-[#F98736] "></div>
                        </div>
                    </div>
                    <div className="w-[85%] mx-auto mt-10 relative" data-aos="fade-up" data-aos-duration="1000">
                        <Slider className='' {...settings} >
                            <div className="px-1 lg:px-3">
                                <div className='bg-[#F1F1F1] shadow-md border rounded-2xl backdrop-blur-sm w-[100%] mb-2 py-5 px-5 md:px-7'>
                                    <div className="flex flex-col items-center text-black">
                                        <div className="flex justify-end w-full"><div className="w-3 h-3 rounded-full bg-purple-500"></div></div>
                                        <p className="mt-3">“Being part of My Business Club feels like joining a community that truly has your back. Beyond the amazing learning and investment opportunities, the referral earning system has been a fantastic way to earn extra income. I've been able to share the benefits of MBC with my network and see real rewards come back to me. It’s more than just a club—it's a place where your success is celebrated and shared.”</p>
                                        <div className="flex gap-5 items-center">
                                            <div className="flex gap-2 mt-5">
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                            </div>
                                            <div className="mt-6">
                                                <p className="font-semibold text-sm">5.0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-1 lg:px-3">
                                <div className='bg-[#F1F1F1] shadow-md border rounded-2xl backdrop-blur-sm w-[100%] mb-2 py-5 px-5 md:px-7'>
                                    <div className="flex flex-col items-center text-black">
                                        <div className="flex justify-end w-full"><div className="w-3 h-3 rounded-full bg-red-500"></div></div>
                                        <p className="mt-3">“When I first joined MBC, I was drawn in by the unique opportunities, but what really impressed me was the referral program. Not only have I grown personally and professionally through the resources and support offered, but I’ve also earned extra
                                            income by referring others to the club. It's a win-win situation that has made me even more invested in the community and my own success.”</p>
                                        <div className="flex gap-5 items-center">
                                            <div className="flex gap-2 mt-5">
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                            </div>
                                            <div className="mt-6">
                                                <p className="font-semibold text-sm">5.0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-1 lg:px-3">
                                <div className='bg-[#F1F1F1] shadow-md border rounded-2xl backdrop-blur-sm w-[100%] py-5 px-5 md:px-7'>
                                    <div className="flex flex-col items-center text-black">
                                        <div className="flex justify-end w-full"><div className="w-3 h-3 rounded-full bg-yellow-500"></div></div>
                                        <p className="mt-3">"At first, I wasn’t sure what to expect, but it’s been one of the best decisions I’ve ever made. The resources and support I’ve received have not only improved my financial situation but also my mindset. The way the club integrates technology and education is incredible, and I feel more secure and informed in my business decisions than ever before. MBC has truly made a difference in my life."</p>
                                        <div className="flex gap-5 items-center">
                                            <div className="flex gap-2 mt-5">
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                                            </div>
                                            <div className="mt-6">
                                                <p className="font-semibold text-sm">5.0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className='sec-8 py-10'>
                <div>
                    <div className='flex gap-3 relative items-center justify-center'  data-aos='fade-right' data-aos-duration='1000'>
                        <svg class="h-8 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H1" />
                        </svg>
                        <h1 className='text-uppercase font-Artemus font-bold text-2xl text-shadow-custom'>FAQ'S</h1>
                    </div>
                    <p className='text-xl md:text-3xl text-center font-semibold tracking-wide mt-3'  data-aos='fade-up' data-aos-duration='1250'>
                        Questions ? <span className='text-gradient'> Look here</span>
                    </p>
                    <div>
                    <Faq/>
                    </div>
                </div>
            </div>
            <div className='section-9 lg:min-h-screen  overflow-hidden flex items-center bg-gradient-to-b from-[#E43875] to-[#F98736]'>
                <div className='flex flex-col lg:flex-row w-[90%] items-center mx-auto gap-10 lg:gap-0 py-10 lg:py-0'>
                    <div className='w-[100%] lg:w-[50%]' data-aos="fade-up" data-aos-duration="1250">
                        <div className='w-full h-full md:px-10'>
                            <p className='text-xl md:text-3xl font-semibold tracking-wide text-white'>Join Us in Creating</p>
                            <p className='text-xl md:text-3xl font-semibold tracking-wide text-white mt-1'>Something Great and Futuristic</p>
                            <form onSubmit={handleSubmit} className='w-[100%] mx-auto mt-5 flex flex-col gap-3 text-[#F23985] font-semibold'>
                                <div className='flex gap-2'>
                                    <input
                                        name='firstName'
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className='w-[50%] py-2 rounded px-3 outline-none'
                                        placeholder='First Name*'
                                        required
                                    />
                                    <input
                                        name='lastName'
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className='w-[50%] py-2 rounded px-3 outline-none'
                                        placeholder='Last Name*'
                                        required
                                    />
                                </div>
                                <div className='flex gap-2'>
                                    <input
                                        name='email'
                                        value={formData.email}
                                        onChange={handleChange}
                                        className='w-[50%] py-2 rounded px-3 outline-none'
                                        placeholder='Email*'
                                        type='email'
                                        required
                                    />
                                    <input
                                        name='phoneNumber'
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        className='w-[50%] py-2 rounded px-3 outline-none'
                                        placeholder='Phone Number*'
                                        type='tel'
                                        required
                                    />
                                </div>
                                <div>
                                    <input
                                        name='subject'
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className='w-[100%] py-2 rounded px-3 outline-none'
                                        placeholder='Subject*'
                                        required
                                    />
                                </div>
                                <div>
                                    <textarea
                                        name='message'
                                        value={formData.message}
                                        onChange={handleChange}
                                        className='w-[100%] py-2 rounded px-3 h-[120px] outline-none'
                                        placeholder='Message*'
                                        required
                                    />
                                </div>
                                <div className='flex justify-center text-white'>
                                    <div className='bg-white p-[3px] cursor-pointer rounded-full my-auto group flex overflow-hidden relative'>
                                        <FontAwesomeIcon icon={faArrowRight} className='bg-black text-[#F98736] rounded-full -translate-x-10 duration-500 group-hover:translate-x-0 px-[10px] py-2 absolute' />
                                        <div className='flex gap-2 duration-500 group-hover:translate-x-10'>
                                            <button type='submit' className='py-1 px-3 rounded-full font-bold bg-gradient-to-r from-[#E43875] to-[#F98736]'>Send Message</button>
                                            <FontAwesomeIcon icon={faArrowRight} className='bg-black text-[#F98736] rounded-full px-[10px] py-2 ' />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='w-[100%] lg:w-[50%]' data-aos="flip-left" data-aos-duration="1000">
                        <img src='/assets/form.png' className='mx-auto md:w-[85%]'></img>
                    </div>
                </div>
            </div>
        </div>
    )
}
