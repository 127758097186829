import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faChevronRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { MORALIS_API_KEY, SERVER_URL, TOKEN_ADDRESS, MBCPayOut_ContractAddress, MBCToken_ContractAddress, PROVIDER_URL } from '../../config';
import { ethers, Wallet } from 'ethers';
import BigNumber from "bignumber.js";
import { MBCPayOut_abi } from '../../ABI';

export default function Analysis() {

    const user = useSelector((state) => state.user);
    const name = user[0].name;
    const email = user[0].email;
    const CusID = user[0].CusID

    const [DepositAddress, setDepositAddress] = useState(null)
    const [IncomeAddress, setIncomeAddress] = useState(null)
    const [DepositBalance, setDepositBalance] = useState(0)
    const [Team, setTeam] = useState(0)
    const [Subscription, setSubscription] = useState(0)
    const [IncomeBalance, setIncomeBalance] = useState(0)
    const [transactions, setTransactions] = useState([])
    const [SubscribeHistory, setSubscribeHistory] = useState([])

    const getUserDetails = async () => { 
        try {
            const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
            let contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
            let getDetails = await contract.getuserDetails(IncomeAddress);
            console.log("userdetails",getDetails);
            setIncomeBalance(new BigNumber(getDetails[7] / 10 ** 18).toFixed());
            setSubscription(new BigNumber(getDetails[4] / 10 ** 18).toFixed());
            setTeam(parseInt(getDetails[6]));

        } catch (err) {
            console.error(err)
        }
    }

    const fetchTokenValues = async (address) => {
        try {
            const response = await fetch(`https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=bsc`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'X-API-Key': MORALIS_API_KEY
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const targetToken = data.result.find(token => token.token_address.toLowerCase() === TOKEN_ADDRESS.toLowerCase());
            if (targetToken) {
                const balance = parseFloat(targetToken.balance) / (10 ** targetToken.decimals);
                return balance;
            } else {
                return 0;
            }

        } catch (error) {
            console.error('Error fetching token balance:', error);
            return null;
        }
    };
    useEffect(() => {
        if (DepositAddress !== null) {
            fetchTokenValues(DepositAddress).then((balance) => setDepositBalance(balance));
        }
        if (IncomeAddress !== null) {
            getUserDetails()
        }
    }, [DepositAddress, IncomeAddress]);

    const fetchAddress = async () => {
        const response = await axios.get(`${SERVER_URL}/api/fetchAddress?CusID=${CusID}`);
        if (response.data.status === true) {
            setDepositAddress(response.data.address.deposit_wallet);
            setIncomeAddress(response.data.address.income_wallet)
        }
    }
    const getSubRecords = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/fetchSubsHistory?CusID=${CusID}`);
            if (response.data.status === true) {
                setSubscribeHistory(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getSubRecords()
    }, [user])

    useEffect(() => {
        if (email !== '') {
            fetchAddress();
            fetchData();
        }
    }, [email])

    const fetchData = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/fetchHistory?CusID=${CusID}`);
            if (response.data.status === true) {
                setTransactions(response.data.data);
                console.log(response.data);
            }
        } catch (error) {
            console.error("Error while fetching transactions : ", error)
        }
    }

    const [currentPage1, setCurrentPage1] = useState(1);
    const [entitiesPerPage1, setEntitiesPerPage1] = useState(5);

    const indexOfLastEntity = currentPage1 * entitiesPerPage1;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage1;
    // const currentEntities = transactions ? transactions.slice(indexOfFirstEntity1, indexOfLastEntity1) : [];
    const currentEntities = SubscribeHistory.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount1 = entitiesPerPage1 - currentEntities.length;

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const handlePrevClick1 = () => {
        setCurrentPage1(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick1 = () => {
        setCurrentPage1(prevPage => {
            const totalPages = Math.ceil(transactions.length / entitiesPerPage1);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handleDropdownChange1 = (e) => {
        setEntitiesPerPage1(parseInt(e.target.value));
        setCurrentPage1(1);
    };

    const hostName = `${window.location.protocol}//${window.location.host}`;
    const [link] = useState(`${hostName}/Signup?ref_id=${user[0].CusID}`);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(link).then(() => {
            toast('Link copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    function dateConvert(timestamp) {
        const dateObj = new Date(timestamp);
        const localDate = dateObj.toLocaleDateString();
        return localDate;
    }

    const openImageInNewTab = (entity) => {
        window.open(`https://testnet.bscscan.com/tx/${entity}`, '_blank');
    };

    const getDisplayId = (entity) => {
        const id = entity.TrID ?? entity.trx_hash;
        return typeof id === 'string' ? id.slice(0, 7) : 'N/A';
    };


    return (
        <div data-aos="fade-in" data-aos-duration='2000'>
            <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
                <div className='flex flex-col lg:flex-row gap-5 md:gap-0 justify-between items-center'>
                    <div>
                        <p className='uppercase font-Artemus font-bold text-lg md:text-2xl'>Welcome back, {name} !</p>
                        <p> Subscription Package Cost : $ {Subscription}</p>
                    </div>
                    {Subscription === '0' ? (
                        <div className='text-sm 2xl:text-base flex gap-2 items-center bg-white rounded-md text-black py-1 px-2' data-aos='zoom-in'>
                            <FontAwesomeIcon icon={faCircleExclamation} className='text-[#ff0000]' />
                            Subscribe to the package and receive your referral code
                        </div>
                    ) : (
                        <div className='flex items-center bg-white rounded-md text-black'>
                            <p className='px-4 text-sm truncate'>
                                {link.substring(0, 30)}...
                            </p>
                            <a
                                className='py-2 px-3 bg-[#2794EB] text-white text-lg cursor-pointer'
                                onClick={() => { copyToClipboard(link) }}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </a>
                            <a
                                className='py-2 px-3 bg-[#4dca88] rounded-r-md text-white text-lg'
                                href={`whatsapp://send?text=${link}`}
                            >
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </a>
                        </div>
                    )}
                </div>
                <div className='mt-5 grid gap-5 grid-cols-2 lg:grid-cols-4 content-center'>
                    <div className='border-[2px] border-white/50 rounded-md text-center p-2 shadow-md'>
                        <h1 className='tracking-wider uppercase text-sm'>Deposit Wallet</h1>
                        <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {Number(DepositBalance).toFixed(2)}</p>
                        <p className='text-xs'>Analytics for Deposit Balance</p>
                    </div>
                    <div className='border-[2px] border-white/50 rounded-md text-center p-2 shadow-md'>
                        <h1 className='tracking-wider uppercase text-sm'>Income Wallet</h1>
                        <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {Number(IncomeBalance).toFixed(2)}</p>
                        <p className='text-xs'>Analytics for Income Balance</p>
                    </div>
                    <div className='border-[2px] border-white/50 rounded-md text-center p-2 shadow-md'>
                        <h1 className='tracking-wider uppercase text-sm'>My Team</h1>
                        <p className='uppercase font-Artemus font-bold text-xl my-1'>{Team}</p>
                        <p className='text-xs'>Analytics for Team Member</p>
                    </div>
                    <div className='border-[2px] border-white/50 rounded-md text-center p-2 shadow-md'>
                        <h1 className='tracking-wider uppercase text-sm'>Total Subscription</h1>
                        <p className='uppercase font-Artemus font-bold text-xl my-1'>${Subscription}</p>
                        <p className='text-xs'>Analytics for Team Member</p>
                    </div>
                </div>
                <div className='mt-5 flex flex-col md:flex-row gap-5'>
                    <div className='md:w-[100%]'>
                        <div className=' border border-white/50 border-dashed rounded-md pb-3'>
                            <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3'>
                                <div className='md:w-[40%]'>
                                    <p className='my-auto font-bold text-lg'>Transaction reports</p>
                                </div>
                                <div className='flex items-center'>
                                    <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                        <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange1} value={entitiesPerPage1}>
                                            <option className='text-black' value="5">5</option>
                                            <option className='text-black' value="7">7</option>
                                            <option className='text-black' value="10">10</option>
                                        </select>
                                    </p>
                                </div>
                            </div>
                            <div className='mb-5 border-y overflow-hidden overflow-x-auto'>
                                <table className='w-full text-center text-sm'>
                                    <thead className='uppercase border-b py-3'>
                                        <tr>
                                            <th className='py-3'>SI.No</th>
                                            <th>Cust ID</th>
                                            <th>Hash</th>
                                            <th>Date</th>
                                            <th>Package Amount</th>
                                            <th>Package</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className='divide-y'>
                                        {currentEntities.length > 0 ? (
                                            currentEntities.map((entity, index) => (
                                                <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                                                    <td className='px-5 md:px-0 font-Artemus'>{index + 1}</td>
                                                    <td className='px-5 md:px-0'>{entity.CustID ?? entity.CusID}</td>
                                                    <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.TrID ?? entity.trx_hash)}>
                                                        {getDisplayId(entity)}...
                                                    </td>
                                                    <td className='px-5 md:px-0'>{formatDate(entity.dot ?? entity.createdAt)}</td>
                                                    <td className='px-5 md:px-0'>${Number(entity.amount).toFixed(0)}</td>
                                                    <td className='px-5 md:px-0'>{entity.plan}</td>
                                                    <td className='px-5 md:px-0 text-green-500'>SUCCESS</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className='text-center h-[280px]'>
                                                    <p>No Data</p>
                                                </td>
                                            </tr>
                                        )}
                                        {currentEntities.length > 0 && Array.from({ length: emptyRowCount1 }).map((_, index) => (
                                            <tr key={`empty1-${index}`} className='h-14'>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-2 flex justify-between text-xs px-3'>
                                <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick1} disabled={currentPage1 === 1}>Prev</button>
                                <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick1} disabled={currentEntities.length < entitiesPerPage1}>Next</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}



