import React from 'react'

export default function Profile() {
    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-xl md:text-2xl font-bold uppercase font-Artemus'>Change Password
                </h1>
                <div className='border rounded-md mt-2 font-poppins'>
                    <div className='px-5 py-3 border-b'>
                        <p className='font-bold'>Change Password ( Regularly change your password for enhanced security! )</p>
                    </div>
                    <div className='px-5 py-3'>
                        <div className='w-[100%] lg:w-[80%] mx-auto flex flex-col gap-3'>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end md:w-[30%] font-semibold'>Old Password</p>
                                <input className='bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md '></input>
                            </div>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end md:w-[30%] font-semibold'>New Password</p>
                                <input className='bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md '></input>
                            </div>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end md:w-[30%] font-semibold'>Confirm Password</p>
                                <input className='bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md '></input>
                            </div>
                            <div className='flex justify-center'>
                                <button className='px-5 py-2 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' >Change Password</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
