import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../config';
const Support = () => {
    const user = useSelector((state) => state.user);
    const email = user[0].email; // Get email from Redux state

    const [formData, setFormData] = useState({
        mailId: email,
        subject: '',
        category: '',
        description: '',
    });
    const [image, setImage] = useState(null); // State for the uploaded image
    const [tickets, setTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket
    const [isEditing, setIsEditing] = useState(false); // State to toggle between view and edit mode

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

    if (file && file.size > maxSize) {
        alert("File size exceeds 10 MB. Please select a smaller file.");
        return;
    }
        setImage(e.target.files[0]); // Update image state with selected file
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('mailId', formData.mailId);
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('category', formData.category);
        formDataToSend.append('description', formData.description);
        if (image) {
            formDataToSend.append('image', image); // Append image file if selected
        }

        try {
            const response = await fetch(`${SERVER_URL}/api/userRaiseTickets`, {
                method: 'POST',
                body: formDataToSend,
            });

            if (response.ok) {
                console.log('Ticket submitted successfully');
                toast.success('Ticket Submitted Successfully');
                setFormData({ mailId: email, subject: '', category: '', description: '' });
                setImage(null); // Clear image after submission
            } else {
                console.error('Error submitting ticket');
                toast.error('Failed to submit ticket');
            }
        } catch (error) {
            console.error('Error submitting ticket:', error);
        }
    };

    const fetchTickets = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/api/getTicketsByEmail?email=${email}`);
            const data = await response.json();

            if (data.tickets) {
                setTickets(data.tickets); // Assuming your API returns { tickets: [...] }
            } else {
                setTickets([]); // Handle cases where no tickets are returned
            }
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    useEffect(() => {
        fetchTickets(); // Fetch tickets on component mount
    }, []);

    const handleTicketClick = (ticket) => {
        setSelectedTicket(ticket);
        console.log(ticket); // Set the selected ticket for more details
    };

    const handleCloseModal = () => {
        setSelectedTicket(null); // Close the modal
        setIsEditing(false); // Close editing mode
    };

    const handleEditDescription = () => {
        setIsEditing(true); // Toggle to editing mode
    };

    const handleSaveDescription = async () => {
        // Call the API to update the ticket with the new description
        try {
            const response = await fetch(`${SERVER_URL}/api/updateTicketDescription/${selectedTicket.token_id}`, {
                method: 'POST',
                body: JSON.stringify({ description: selectedTicket.description }),
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) {
                toast.success('reply sent successfully');
                setSelectedTicket({ ...selectedTicket, description: selectedTicket.description });
                setIsEditing(false); // Exit editing mode
            } else {
                toast.error('Failed to sent the reply');
            }
        } catch (error) {
            console.error('Error sending the reply:', error);
        }
    };

    const handleCloseTicket = async () => {
        // Call API to change the ticket status to 'closed'
        try {
            const response = await fetch(`${SERVER_URL}/api/closeTicket/${selectedTicket.token_id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) {
                toast.success('Ticket closed successfully');
                // Remove ticket from the UI
                setTickets(tickets.filter(ticket => ticket.token_id !== selectedTicket.token_id));
                setSelectedTicket(null); // Close the modal
            } else {
                // Display an error with a more detailed message
                const errorData = await response.json();
                toast.error(`Failed to close the ticket: ${errorData.error || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error closing ticket:', error);
            toast.error('Error closing the ticket');
        }
    };

    return (
        <div>
            <h2 className="text-lg font-bold text-white">Support</h2>
            <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                <div className='w-full py-2 rounded-md border border-white/75'>
                    {/* <p className='text-xs text-white/70 px-5'>Email</p> */}
                    <input
                        type="email"
                        name="mailId"
                        className='w-full bg-transparent px-5 text-white outline-none cursor-not-allowed placeholder:text-white/50'
                        value={formData.mailId}
                        disabled // Disabled email field
                    />
                </div>
                <div className='w-full py-2 rounded-md border border-white/75'>
                    {/* <p className='text-xs text-white/70 px-5'>Subject</p> */}
                    <input
                        type="text"
                        name="subject"
                        className='w-full bg-transparent px-5 text-white outline-none placeholder:text-white/50'
                        placeholder='Subject'
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='w-full py-2 rounded-md border border-white/75'>
                    {/* <p className='text-xs text-white/70 px-5'>Category</p> */}
                    <input
                        type="text"
                        name="category"
                        className='w-full bg-transparent px-5 text-white outline-none placeholder:text-white/50'
                        placeholder='Category'
                        value={formData.category}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='w-full py-2 rounded-md border border-white/75'>
                    {/* <p className='text-xs text-white/70 px-5'>Description</p> */}
                    <textarea
                        name="description"
                        className='w-full bg-transparent px-5 text-white outline-none placeholder:text-white/50'
                        placeholder='Describe the issue'
                        value={formData.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='w-full py-2 rounded-md border border-white/75'>
                    <p className='text-xs text-white/70 px-5'>Issue screenshot (optional)</p>
                    <input
                        type="file"
                        name="image"
                        className='w-full bg-transparent px-5 text-white outline-none'
                        onChange={handleImageChange}
                        accept="image/*,video/*"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full py-2 mt-2 bg-blue-500 rounded-md text-white hover:bg-blue-600"
                >
                    Submit Ticket
                </button>
            </form>

            <div className="mt-6">
                <h3 className="text-lg font-bold text-white">Raised Tickets</h3>
                <div className="space-y-4 mt-2">
                    {tickets.length > 0 ? (
                        tickets.map((ticket) => (
                            <div
                                key={ticket.token_id}
                                className="border border-white/75 p-4 rounded-md cursor-pointer"
                                onClick={() => handleTicketClick(ticket)} // Make ticket clickable
                            >
                                <h4 className="text-md font-semibold text-white">{ticket.token_id}</h4>
                                <p className="text-white/70">Subject: {ticket.subject}</p>
                                <p className="text-white/70">Status: {ticket.status}</p>
                            </div>
                        ))
                    ) : (
                        <p className="text-white/70">No tickets raised yet.</p>
                    )}
                </div>
            </div>

            {selectedTicket && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-md shadow-lg text-black">
                        <h4><strong>Ticket ID:</strong> {selectedTicket.token_id || 'No Subject'}</h4>
                        <p><strong>Subject:</strong> {selectedTicket.subject || "N/A"}</p>
                        <p><strong>Status:</strong> {selectedTicket.status || 'N/A'}</p>
                        <p><strong>you:</strong> {selectedTicket.description || 'N/A'}</p>
                        <p><strong>Admin:</strong></p>
                        {isEditing ? (
                            <textarea
                                value={selectedTicket.description}
                                onChange={(e) => setSelectedTicket({ ...selectedTicket, description: e.target.value })}
                                rows="4"
                                className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                            />
                        ) : (
                            <p>{selectedTicket.admin_reply || 'No reply yet.'}</p>
                        )}

                        <p><strong>Updated at:</strong> {selectedTicket.updated_at || 'N/A'}</p>
                        <div className="mt-4 flex space-x-2">
                            {selectedTicket.status !== 'closed' && (
                                <>
                                    {isEditing ? (
                                        <button
                                            onClick={handleSaveDescription}
                                            className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                        >
                                            Send Reply
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleEditDescription}
                                            className="bg-yellow-500 text-white px-4 py-2 rounded-md"
                                        >
                                            Reply
                                        </button>
                                    )}
                                    <button
                                        onClick={handleCloseTicket}
                                        className="bg-red-500 text-white px-4 py-2 rounded-md"
                                    >
                                        Close
                                    </button>
                                </>
                            )}
                            <button
                                onClick={handleCloseModal}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer />
        </div>
    );
};

export default Support;
