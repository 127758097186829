import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Suspensionid() {
    const tableEntries = [
        {
            id: "1",
            ref: "Procress",
            custID: "708944",
            email: "tihonovavalya1951@gmail.com",
            custusername: "saidulislam",
            entrydate: "5/21/2024 7:32:58 AM",
            approved: "1",
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };


    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Suspended ID</h1>
                <div className=' rounded-lg mt-5 border '>
                    <div className='px-5 py-3 border-b  font-bold text-lg'>
                        <p>Customer List ( Showing Not Suspended Customers )</p>
                    </div>
                    <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                        <div className=' text-sm font-bold m-auto'>
                            Total Record : 4
                        </div>
                        <div className='flex md:grid gap-1 m-auto '>
                            <p className='text-sm font-bold'>Customer Type </p>
                            <select className='bg-transparent border rounded-md py-1 w-[100%] text-center outline-none'>
                                <option className='text-black'>Suspended</option>
                                <option className='text-black '>Not Suspended</option>
                            </select>
                            <div>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>List</button>
                            </div>
                        </div>
                        <div className='flex md:grid gap-1 m-auto'>
                            <div className='flex items-center gap-1 text-sm text-nowrap'>
                                <p className='font-bold'>MemberID : </p>
                                <input className='w-[50%] border rounded-md bg-transparent py-1'></input>
                            </div>
                            <div>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Submit</button>
                            </div>
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
                            <div className='w-[25%] md:w-[100%]'>
                                <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                            </div>
                        </div>
                        <div className=' text-sm font-bold m-auto'>
                            Page of 1 of 0
                        </div>
                    </div>
                </div>
                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'>Ref</th>
                                    <th className='px-2'>CustID</th>
                                    <th className='px-2'>Email</th>
                                    <th className='px-2'>Cust UserName</th>
                                    <th className='px-2'>Entry Date</th>
                                    <th className='px-2'>Approved</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{entity.id}</td>
                                        <td className='px-5 md:px-2'>{entity.ref}</td>
                                        <td className='px-5 md:px-2'>{entity.custID}</td>
                                        <td className='px-5 md:px-2'>{entity.email}</td>
                                        <td className='px-5 md:px-2'>{entity.custusername}</td>
                                        <td className='px-5 md:px-2'>{entity.entrydate}</td>
                                        <td className='px-5 md:px-2'>{entity.approved}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
