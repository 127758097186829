import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";
import { MORALIS_API_KEY, SERVER_URL, TOKEN_ADDRESS, MBCPayOut_ContractAddress, MBCToken_ContractAddress, PROVIDER_URL } from '../../../config';
import { MBCPayOut_abi, token_abi } from '../../../ABI';

export default function Addfunds() {

  const user = useSelector((state) => state.user);
  const name = user[0].name;
  const email = user[0].email;
  const CusID = user[0].CusID

  const [amount, setAmount] = useState("");
  const [DepositAddress, setDepositAddress] = useState(null)
  const [IncomeAddress, setIncomeAddress] = useState(null)
  const [transactions, setTransactions] = useState([])

  const [IncomeBalance, setIncomeBalance] = useState(0)
  const [DepositBalance, setDepositBalance] = useState(0)


  const fetchTokenValues = async (address) => {
    try {
      const response = await fetch(`https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=bsc`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': MORALIS_API_KEY
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const targetToken = data.result.find(token => token.token_address.toLowerCase() === TOKEN_ADDRESS.toLowerCase());
      if (targetToken) {
        const balance = parseFloat(targetToken.balance) / (10 ** targetToken.decimals);
        return balance;
      } else {
        return 0;
      }

    } catch (error) {
      console.error('Error fetching token balance:', error);
      return null;
    }
  };

  const getUserDetails = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
      let contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
      let getDetails = await contract.getuserDetails(IncomeAddress);
      setIncomeBalance(parseFloat(getDetails[7] / 1e6));
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (DepositAddress !== null) {
      fetchTokenValues(DepositAddress).then((balance) => setDepositBalance(balance));
    }
    if (IncomeAddress !== null) {
      getUserDetails()
    }
  }, [DepositAddress, IncomeAddress]);

  const fetchHistory = async () => {
    try {
      let response = await axios.get(`${SERVER_URL}/api/fetchDepositHistory?CusID=${CusID}`);
      if (response.data.status === true) {
        console.log(response.data.data);
        setTransactions(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }

  }

  const fetchAddress = async () => {
    const response = await axios.get(`${SERVER_URL}/api/fetchAddress?email=${email}`);
    if (response.data.status === true) {
      setDepositAddress(response.data.address.deposit_wallet);
      setIncomeAddress(response.data.address.income_wallet)
    }
  }

  useEffect(() => {
    if (email !== '') {
      fetchAddress();
    }
    fetchHistory()
  }, [email])

  const handleDeposit = async () => {

    if (!window.ethereum) {
      toast.error("Please install MetaMask!");
      return;
    }
    if (!amount) {
      toast.error("Enter a valid amount");
      return;
    }
    const toastId = toast.loading("Token transaction in progress");

    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      await switchToBscTestnet();
      const TokenAddress = TOKEN_ADDRESS;
      const TokenAbi = token_abi;

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(TokenAddress, TokenAbi, signer);

      const network = await provider.getNetwork();
      const networkName = network.name;
      const senderAddress = await signer.getAddress();

      const TransferAmount = ethers.BigNumber.from((amount * 10 ** 18).toFixed(0));
      
      const tokenBalance = await contract.balanceOf(senderAddress);
      if (TransferAmount.gt(tokenBalance)) {
        toast.error("Insufficient amount");
        toast.dismiss(toastId);
        return;
      
      }
      let recipientAddress = DepositAddress;
      const tx = await contract.transfer(recipientAddress, TransferAmount);
      await tx.wait();

      const receipt = await tx.wait();

      toast.dismiss(toastId);
      toast.success("Deposit successfull!");
      setAmount("");

      const gasFeeInWei = tx.gasPrice.mul(receipt.gasUsed);
      const gasFeeInEth = ethers.utils.formatUnits(gasFeeInWei, 'ether');

      const transactionData = {
        email: email,
        CusID: CusID,
        network: networkName,
        currency: 'USDT',
        receiverAddress: DepositAddress,
        senderAddress: senderAddress,
        hash: tx.hash,
        gasFee: gasFeeInEth,
        amount: amount,
        status: "success",
      };
      console.log(transactionData);

      let res = await axios.post(`${SERVER_URL}/api/DebitAmount`, {
        email: email,
        CusID: CusID,
        network: networkName,
        currency: 'USDT',
        receiverAddress: DepositAddress,
        senderAddress: senderAddress,
        hash: tx.hash,
        gasFee: gasFeeInEth,
        amount: amount,
        status: "success",
      });

      if (res.data.status === true) {
        toast.success('Stored Successfully !');
      }

    } catch (error) {
      console.error("Error depositing:", error);
      toast.dismiss(toastId);

      if (error.code === 'INSUFFICIENT_FUNDS') {
        toast.error("You don't have enough funds to complete this transaction.");
      } else if (error.code === 'NETWORK_ERROR') {
        toast.error("Network error occurred. Please check your connection.");
      } else if (error.code === 'CALL_EXCEPTION') {
        toast.error("Call exception: Issue with the smart contract interaction.");
      } else if (error.message.includes('revert')) {
        toast.error("Transaction reverted by the smart contract. Please check conditions.");
      } else if (error.message.includes('insufficient funds')) {
        toast.error("Insufficient balance to cover the transaction and gas fees.");
      } else if (error.message.includes('gas limit')) {
        toast.error("Transaction failed due to gas limit issues. Consider increasing the gas limit.");
      } else if (error.code === 4001) {
        toast.error("Transaction rejected by the user.");
      } else {
        toast.error(`Transaction failed: ${error.message}`);
      }
    }
  };

  async function switchToBscTestnet(chainId) {
    if (chainId === 56) {
      return;
    } else {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexValue(56),
            },
          ],
        });
        // toast.success("Network changed successfully to BSC Testnet");
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: ethers.utils.hexValue(56),
                  chainName: "Binance Smart Chain",
                  nativeCurrency: {
                    name: "Binance Coin",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  rpcUrls: [PROVIDER_URL],
                  blockExplorerUrls: ["https://bscscan.com/"],
                },
              ],
            });
          } catch (addError) {
            toast.error("Failed to add the BSC Testnet");
            console.error("Error adding the network:", addError);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
          toast.error("Failed to switch the network to BSC Testnet");
          console.error("Error switching the network:", switchError);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    }
  }


  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(transactions.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(transactions.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  function dateConvert(timestamp) {
    const dateObj = new Date(timestamp);
    const localDate = dateObj.toLocaleDateString();
    return localDate;
  }

  const openImageInNewTab = (entity) => {
    window.open(`https://bscscan.com/tx/${entity}`, '_blank');
  };

  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
        <div className='flex justify-center gap-5'>
          <div className='border-[2px] md:w-[30%] border-white/50 rounded-md text-center px-2 py-5 shadow-md'>
            <h1 className='tracking-wider uppercase text-sm'>Deposit Wallet</h1>
            <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {Number(DepositBalance).toFixed(2)}</p>
            <p className='text-xs'>Analytics for Deposit Balance</p>
          </div>
          <div className='border-[2px] md:w-[30%] border-white/50 rounded-md text-center p-2 py-5 shadow-md'>
            <h1 className='tracking-wider uppercase text-sm'>Income Wallet</h1>
            <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {Number(IncomeBalance).toFixed(2)}</p>
            <p className='text-xs'>Analytics for Income Balance</p>
          </div>
        </div>
        <p className='mt-10 text-center'>If you have not received payment in few hours please generate support ticket with transaction details.</p>
        <div className='md:w-[70%] mx-auto border px-7 py-3 rounded-md mt-10'>
          <p>Enter Amount to Deposit in Deposit wallet</p>
          <p>Amount in USD Only</p>
          <input className='w-[100%] border rounded-md px-5 py-2 bg-transparent mt-2' placeholder='Enter Value' type="text" value={amount} onChange={(e) => setAmount(e.target.value)}  ></input>
          <div className='flex justify-center'><button className='mt-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md ' onClick={handleDeposit}>Click here Deposit Fund</button></div>
        </div>
        <div className='rounded-2xl mt-5 md:w-[70%] mx-auto'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
            <div className='md:w-[40%]'>
              <p className='my-auto font-bold text-xl '>Deposit History</p>
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 border border-dashed rounded-xl overflow-hidden overflow-x-auto'>
            <table className='w-full text-center  '>
              <thead className='uppercase font-bold text-sm border-b py-3'>
                <tr>
                <th className='py-3'>SI.No</th>
                  <th className='py-3'>TrID</th>
                  <th> Amount</th>
                  <th>DATE</th>
                  <th>Status</th>

                </tr>
              </thead>
              <tbody className='divide-y'>
                {currentEntities.map((entity, index) => (
                  <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                    <td className='px-5 md:px-0 font-Artemus'>{index + 1}</td>
                    <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.transaction_hash)}>
                      {entity.transaction_hash.slice(0, 10)}...
                    </td>
                    <td className='px-5 md:px-0'>{entity.amount > 0 ? Number(entity.amount).toFixed(2) : '0.00'}</td>
                    <td className='px-5 md:px-0'>{dateConvert(entity.created_at)}</td>
                    <td className='px-5 md:px-0'>{entity.status}</td>
                  </tr>
                ))}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}