import React, { useState ,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function ClosedTicket() {
    const tableEntries = [
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [tickets, setTickets] = useState([]);
    const [replyInput, setReplyInput] = useState('');
    const [replyTicketId, setReplyTicketId] = useState(null);


    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const response = await fetch('http://localhost:3006/api/fetchClosedTickets');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // Assuming your API returns tickets in an array directly
                setTickets(Array.isArray(data) ? data : data.tickets || []); // Adjust as needed
            } catch (error) {
                console.error("Error fetching tickets:", error);
            }
        };

        fetchTickets();
    }, []);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = Array.isArray(tickets) ? tickets.slice(indexOfFirstEntity, indexOfLastEntity) : [];
    const emptyRowCount = entitiesPerPage - currentEntities.length;


    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tickets.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };


    // Calculate pagination details
    const totalPages = Math.ceil(tickets.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Closed Ticket
                </h1>
                <div className=' rounded-lg mt-2 border '>
                    <div className='px-5 py-3 border-b  font-bold'>
                        <p>Filter Your Search</p>
                    </div>
                    <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                        <div className=' text-sm font-bold m-auto'>
                        Total Record : {tickets.length}
                        </div>
                        <div></div>
                        <div></div>
                        <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
                            <div className='w-[25%] md:w-[100%]'>
                                <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                            </div>
                        </div>
                        <div className=' text-sm font-bold m-auto'>
                        Page of {currentPage} of {totalPages}
                        </div>
                    </div>
                </div>

                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b'>
                        <div>
                        <p className='font-bold'>Showing All Ticket</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center text-[13px]'>
                            <thead className=' uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                <th className='py-3 px-2'>ID</th>
                                    <th className='px-2'>Email ID</th>
                                    <th className='px-2'>Subject</th>
                                    <th className='px-2'>Category</th>
                                    <th className='px-2'>Token ID</th>
                                    <th className='px-2'>Status</th>
                                    <th className='px-2'>Updated At</th>
                                    <th className='px-2'>Images</th>
                                    <th className='px-2'>User reply</th>
                                    {/* <th className='px-2'>Updated At</th> */}
                                    <th className='px-2'>Admin reply</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                            {currentEntities.map((ticket) => (
                                    <tr key={ticket.id} className='h-16 text-center rounded-md font-bold'>
                                        <td className='px-2'>{ticket.id}</td>
                                        <td className='px-2'>{ticket.mail_id}</td>
                                        <td className='px-2'>{ticket.subject}</td>
                                        <td className='px-2'>{ticket.category}</td>
                                        <td className='px-2'>{ticket.token_id}</td>
                                        <td className='px-2'>{ticket.status}</td>
                                        <td className='px-2'>{ticket.updated_at}</td>
                                        <td className='px-2 flex justify-center items-center'>
                                            {ticket.fullImageUrl ? (
                                                <img
                                                src={ticket.fullImageUrl}
                                                alt="Ticket"
                                                className="h-12 w-12 object-cover cursor-pointer"
                                                onClick={() => window.open(ticket.fullImageUrl, '_blank')} // Open in new tab
                                              />
                                            ) : (
                                                <span>No Image</span>
                                            )}
                                        </td>
                                        {/* <td className='px-2'>{ticket.updated_at}</td> */}
                                        <td className='px-2'>{ticket.description}</td>
                                        <td className='px-2'>{ticket.admin_reply}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
