import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function ActiveTeam() {
  const tableEntries = [
    {
      SNo: "1",
      memberid: "638246",
      username: "MBCAfrica",
      level: "1",
      date: "10/05/2002",
      time: "10:12 AM",
      activation: "$30",
      amount: "30",
      Groupbiz: '0',
    },

  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  // Determine the current entries to display
  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  // Handle previous and next page clicks
  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle dropdown change for entities per page
  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const openImageInNewTab = (entity) => {
    window.open(entity.doc, '_blank');
  };

  // Calculate pagination details
  const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
        <p className='my-auto font-bold text-xl font-Artemus uppercase'>Active Team Information</p>
        <div className='flex flex-col md:flex-row gap-5 md:gap-0 justify-between items-center mt-5 text-sm'>
          <div>
            <p>Username : <span className='font-bold'>MBC</span></p>
          </div>
          <div>
            <p>Date of Joining : 08-May-2024 10:56:31 AM</p>
            <p>Date of Activation : 11-May-2024 10:30:47 AM</p>
          </div>
        </div>
        <div className='rounded-2xl mt-5'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
            <div className='md:w-[35%] '>
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
            <table className='w-full text-center  '>
              <thead className='text-sm uppercase font-bold border-b py-3 '>
                <tr>
                  <th className='py-3 px-2'>Sr.No.</th>
                  <th className='px-2'>Member Id</th>
                  <th className='px-2'>User Name</th>
                  <th className='px-2 '>Level</th>
                  <th className='px-2'>Date of Registration		</th>
                  <th className='px-2'>Date of Activation	</th>
                  <th className='px-2 '>Active Package ($)</th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                {currentEntities.map((entity, index) => (
                  <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                    <td className='px-5 md:px-2'>{entity.SNo}</td>
                    <td className='px-5 md:px-2'>{entity.memberid}</td>
                    <td className='px-5 md:px-2'>{entity.username}</td>
                    <td className='px-5 md:px-2 '>{entity.level}</td>
                    <td className='px-5 md:px-2'>{entity.date} {entity.time}</td>
                    <td className='px-5 md:px-2'>{entity.activation}</td>
                    <td className='px-5 md:px-2'>{entity.amount}</td>
                  </tr>
                ))}
                {/* Add empty rows if needed */}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}
