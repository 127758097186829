import { faChevronDown, faChevronUp, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState,useEffect } from 'react'
import IncomeWallet from './Apexchart/IncomeWallet'
import DepositWallet from './Apexchart/DepositWallet'

export default function GotoDashboard() {

    const [counts, setCounts] = useState({ totalMembers: 0, activeMembers: 0 });

    useEffect(() => {
        const fetchMemberCounts = async () => {
            try {
                const response = await fetch('http://localhost:3006/api/memberCounts'); // Adjust the URL as needed
                const data = await response.json();
                setCounts(data);  
                console.log(data);  // This will log the response containing both counts
            } catch (error) {
                console.error('Error fetching member counts:', error);
            }
        };
    
        fetchMemberCounts();
    }, []);

    // Calculate inactive members dynamically
    const inactiveMembers = counts.totalMembers - counts.activeMembers;


    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-5 mt-5 font-poppins'>
                <div className='grid grid-cols-3 text-[13px] text-nowrap text-center truncate md:grid-cols-3 lg:grid-cols-6 content-between p-3 divide-x divide-x-white/50  border border-white/50 rounded-md shadow-md shadow-black/30'>
                    <div className=' pr-3 grid content-between'>
                        <h1 className='font-bold truncate'><FontAwesomeIcon icon={faUser} className='pr-1' /> Total Members</h1>
                        <p className='mt-2 text-4xl font-bold font-Artemus'>{counts.totalMembers}</p>
                        <p className=''>Registered Members</p>
                    </div>
                    <div className='px-3 grid content-between'>
                        <h1 className='font-bold truncate'><FontAwesomeIcon icon={faUser} className='pr-1' /> Active Members</h1>
                        <p className='mt-2 text-4xl font-bold font-Artemus'>{counts.totalMembers}</p>
                        <p className=''>Active Users</p>
                    </div>
                    <div className='px-3 grid content-between'>
                        <h1 className='font-bold truncate'><FontAwesomeIcon icon={faUser} className='pr-1' />  Deactive Members</h1>
                        <p className='mt-2 text-4xl font-bold font-Artemus'>0</p>
                        <p className=''>Inactive Users</p>
                    </div>
                    {/* <div className='px-3 grid content-between'>
                        <h1 className='font-bold truncate'><FontAwesomeIcon icon={faUser} className='pr-1' />  Upgraded Members</h1>
                        <p className='mt-2 text-4xl font-bold font-Artemus'>341</p>
                        <p className=''>Topup Members</p>
                    </div>
                    <div className='px-3 grid content-between'>
                        <h1 className='font-bold truncate'><FontAwesomeIcon icon={faUser} className='pr-1' />  Not Upgraded Members</h1>
                        <p className='mt-2 text-4xl font-bold font-Artemus'>341</p>
                        <p className=''>Un Topup Members</p>
                    </div>
                    <div className='pl-3 grid content-between'>
                        <h1 className='font-bold truncate'><FontAwesomeIcon icon={faUser} className='pr-1' />  Trading Profit</h1>
                        <p className='mt-2 text-4xl font-bold font-Artemus'>341</p>
                        <p className=''>Investment</p>
                    </div> */}
                </div>
                {/* <div className='flex flex-col md:flex-row gap-5 mt-5'>
                    <div className='w-[50%] border rounded-md'>
                        <div
                            className="cursor-pointer border-b px-4 py-2 flex justify-between items-center"
                        >
                            <h2 className="font-semibold text-lg">Income Wallet</h2>

                        </div>
                        <div className='px-5 py-3'>
                            <IncomeWallet />
                        </div>
                        <div className="text-center mb-4">
                            <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg">
                                Transaction Report
                            </button>
                        </div>
                    </div>
                    <div className='w-[50%] border rounded-md'>
                        <div
                            className="cursor-pointer border-b px-4 py-2 flex justify-between items-center"
                        >
                            <h2 className="font-semibold text-lg">Deposit Wallet</h2>
                        </div>
                        <div className='px-5 py-3'>
                            <DepositWallet />
                        </div>
                        <div className="text-center mb-4">
                            <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg">
                                Transaction Report
                            </button>
                        </div>
                    </div>
                </div> */}
                {/* <div className='flex flex-col md:flex-row gap-5 mt-5'>
                    <div className="border border-gray-300 rounded-lg shadow-lg md:w-[50%]">
                        <div
                            className="cursor-pointer border-b px-4 py-2 flex justify-between items-center"
                        >
                            <h2 className="font-semibold text-lg">Customer Upgrade</h2>

                        </div>
                        <div className="px-5 py-3">
                            <table className=''>
                                <tbody className='text-sm '>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>1. Total Today Generated ROI (Last Generated Payout)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>2. Expected Tomorrow ROI ($)</th>
                                        <td className='pl-3'><span>2.76666666666667</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>3. Today Business Investment ($)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>4. Total Active ID (count)</th>
                                        <td className='pl-3'><span>133</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>5. Total Deactivated ID (count)</th>
                                        <td className='pl-3'><span>208</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>6. Total Suspended ID (count)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>7. Total ID (count)</th>
                                        <td className='pl-3'><span>341</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>8. Total Business Expired ($)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className='border border-gray-300 rounded-lg shadow-lg md:w-[50%]'>
                        <div
                            className="cursor-pointer border-b px-4 py-2 flex justify-between items-center"
                        >
                            <h2 className="font-semibold text-lg">Investment Total ( $ 6090 )</h2>
                        </div>
                        <div className='px-5 py-3'>
                            <p className='mt-4'>Topup Investment Amount : $ 6090</p>
                            <div className='flex items-center justify-between mt-5'>
                                <p className='text-sm w-[15%]'>Active</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[99%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>$ 6090</p>
                            </div>
                            <div className='flex items-center justify-between mt-3'>
                                <p className='text-sm w-[15%]'>Expired</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[1%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>$ 0</p>
                            </div>
                            <p className='mt-7'>Upgrade Investment Amount : $ 30</p>
                            <div className='flex items-center justify-between mt-5'>
                                <p className='text-sm w-[15%]'>Active</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[99%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>$ 30</p>
                            </div>
                            <div className='flex items-center justify-between mt-3'>
                                <p className='text-sm w-[15%]'>Expired</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[1%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>$ 0</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='flex flex-col md:flex-row gap-5 mt-5'>
                    <div className="border border-gray-300 rounded-lg shadow-lg md:w-[50%]">
                        <div
                            className="cursor-pointer border-b h-[45px] flex justify-between items-center"
                        >

                        </div>
                        <div className="px-5 py-3">
                            <table className=''>
                                <tbody className='text-sm'>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>9. Today Total Withdrawal ($)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>10. Today Total Approved Withdrawal ($)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>11. Today Total Cancel Withdrawal ($)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>12. Global Withdrawal (Till Date) ($)</th>
                                        <td className='pl-3'><span>496</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>13. Global Cancel Withdrawal (Till Date) ($)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>14. Global Active Investment (Till Date) ($)</th>
                                        <td className='pl-3'><span>6090</span></td>
                                    </tr>
                                    <tr>
                                        <th className='w-[70%] font-bold text-start py-2'>15. Global ROI (Till Date) ($)</th>
                                        <td className='pl-3'><span>0</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className='border border-gray-300 rounded-lg shadow-lg md:w-[50%]'>
                        <div
                            className="cursor-pointer border-b px-4 py-2 flex justify-between items-center"
                        >
                            <h2 className="font-semibold text-lg">Login & Wrong Password Feed</h2>
                        </div>
                        <div className='px-5 py-3'>
                            <p className='mt-1'>Login Count : 9276</p>
                            <div className='flex items-center justify-between mt-5'>
                                <p className='text-sm w-[15%]'>Today</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[1%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>7</p>
                            </div>
                            <div className='flex items-center justify-between mt-3'>
                                <p className='text-sm w-[15%]'>Yesterday</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[1%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>12</p>
                            </div>
                            <p className='mt-7'>Wrong Password Feed : 5678</p>
                            <div className='flex items-center justify-between mt-5'>
                                <p className='text-sm w-[15%]'>Today</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[1%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>1</p>
                            </div>
                            <div className='flex items-center justify-between mt-3'>
                                <p className='text-sm w-[15%]'>Yesterday</p>
                                <div className='bg-white/20 h-5 rounded w-[60%]'>
                                    <div className='bg-[#1ABB9C] h-5 rounded w-[1%]'></div>
                                </div>
                                <p className='font-bold w-[15%]'>2</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
