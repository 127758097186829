import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from '../../../config';
import countries from '../../../CountryCode.json';
import states from '../../../States.json';
import toast, { Toaster } from "react-hot-toast";


export default function Account() {
    const user = useSelector((state) => state.user);
    const email = user[0]?.email;
    const CusID = user[0]?.CusID;

    const [isEditable, setIsEditable] = useState(true);
    const [userData, setUserData] = useState({});
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const fetchDatas = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getUsersDatas?CusID=${CusID}`);
            if (response.data && response.status === 200) {

                const data = response.data.data[0];
                setUserData(data);
                setSelectedCountry(data.country);
                setSelectedState(data.city);
            } else {
                console.error("Failed to fetch user data:", response.data.message);
            }
        } catch (err) {
            console.error("Error fetching user data:", err);
        }
    };

    useEffect(() => {
        if (CusID) {
            fetchDatas();
        }
    }, [CusID]);

    const handleUpdateProfile = async () => {
        const formData = new FormData();
        formData.append('CusID', CusID);
        formData.append('name', userData.name);
        formData.append('country', userData.country);
        formData.append('mobile_number', userData.mobile_number);
        formData.append('zip_code', userData.zip_code);
        formData.append('withdraw_wallet', userData.withdraw_wallet);
        formData.append('withdraw_wallet2', userData.withdraw_wallet2);
        formData.append('dob', userData.dob);
        formData.append('address', userData.address);
        formData.append('city', selectedState);

        if (file) {
            formData.append('profile_photo', file);
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/updateProfile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status) {
                console.log('Profile updated successfully');
                toast.success('Profile updated successfully');
                setTimeout(() => {
                    fetchDatas();
                }, 2000);
            } else {
                console.error("Failed to update profile:", response.data.message);
            }
        } catch (err) {
            console.error("Error updating profile:", err);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile ? selectedFile.name : ''); // Set file name
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        setSelectedCountry(country);
        setSelectedState('');
        setUserData((prevData) => ({ ...prevData, country }));
    };

    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        setUserData((prevData) => ({ ...prevData, city: state }));
    };

    const filteredStates = states.filter(state => state.country_name === selectedCountry);

    // Format date to YYYY-MM-DD for input value
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Convert date input to ISO format for storage
    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        // Convert selected date to YYYY-MM-DD format
        const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
        setUserData((prevData) => ({ ...prevData, dob: formattedDate }));
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <p className='mt-5 font-bold text-xl font-Artemus uppercase'>Profile Details</p>
            <div className='flex flex-col lg:flex-row gap-3 md:gap-5 mt-3'>
                {/* <div>
                    {userData.profile_photo ? (
                        <img
                            src={`${SERVER_URL}/uploads/${userData.profile_photo}`}
                            alt='Profile'
                            className='w-20 h-20 rounded-full object-cover'
                        />
                    ) : (
                        <FontAwesomeIcon icon={faCircleUser} className='text-5xl text-white/75' />
                    )}
                </div> */}

                {/* <div>
                    <div className='flex items-center bg-[#E43875] px-3 py-2 shadow-md rounded-md'>
                        <input type='file' onChange={handleFileChange} className='hidden' id='file-input' />
                        <label htmlFor='file-input' className='cursor-pointer'>Choose a photo</label>
                    </div>

                    <p className='text-sm mt-2 text-center'>Allowed JPG, GIF or PNG. Max size of 800K</p>
                </div>
                <div>
                    {fileName && (
                        <p className='text-sm mt-2 text-center'>{fileName}</p>
                    )}
                </div> */}
            </div>

            <div className='flex flex-col md:flex-row gap-7 mt-10'>
                <div className='grid gap-3 md:w-[50%]'>
                    <div className='w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 cursor-not-allowed'>
                        <p className='text-xs 2xl:text-sm text-white/70'>Member</p>
                        <p className='font-semibold'>{CusID}</p>
                    </div>

                    <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!user ? '' : 'cursor-not-allowed'}`}>
                        <p className='text-xs 2xl:text-sm text-white/70'>Full Name</p>
                        <input
                            name='name'
                            className='w-full bg-transparent outline-none'
                            value={userData.name || ''}
                            disabled={user}
                        />
                    </div>

                    

                    {/* <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${isEditable ? '' : 'cursor-not-allowed'}`}>
                        <p className='text-xs 2xl:text-sm text-white/70'>Country</p>
                        <select
                            name='country'
                            className='w-full bg-transparent outline-none'
                            value={userData.country || ''}
                            disabled={user}
                        >
                            <option className='text-black'>--- Select ---</option>
                            {countries.map((country) => (
                                <option key={country.country_code} value={country.country_name} className='text-black'>
                                    {country.country_name}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className='w-[100%] py-2 h-[58px] rounded-md border border-white/75 '>
                        <p className='text-xs 2xl:text-sm text-white/70 px-5'>Country</p>
                        <input
                            name='zip_code'
                            className={`w-full bg-transparent px-5 outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                            placeholder='Country'
                            value={userData.country || ''}
                            disabled={user}
                        />
                    </div>
                    <div className='w-[100%] py-2 h-[58px] rounded-md border border-white/75 '>
                        <p className='text-xs 2xl:text-sm text-white/70 px-5'>Zip-Code</p>
                        <input
                            name='zip_code'
                            className={`w-full bg-transparent px-5 outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                            placeholder='Pin / Zip code'
                            value={userData.Cust_Pincode || ''}
                            disabled={user}
                        />
                    </div>

                    <div className='w-[100%] py-2 h-[58px] rounded-md border border-white/75 '>
                        <p className='text-xs 2xl:text-sm text-white/70 px-5'>Sponsor ID</p>
                        <input
                            name='withdraw_wallet2'
                            className={`w-full bg-transparent px-5 outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                            placeholder='Withdraw Address 2 (USDT -TRC20)'
                            value={userData.sponsorid || ''}
                            disabled={user}

                        />
                    </div>
                </div>

                <div className='grid gap-3 md:w-[50%]'>
                    <div className='w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 cursor-not-allowed'>
                        <p className='text-xs 2xl:text-sm text-white/70'>Email</p>
                        <p className='font-semibold'>{email}</p>
                    </div>

                    {/* <div className='w-[100%] flex'>
                         <div className='w-[30%] h-[58px] py-2 rounded-md border border-white/75 bg-transparent px-5'>
                            <p className='text-xs 2xl:text-sm text-white/70'>Code</p>
                            <p className='font-semibold'>{countries.find(c => c.country_name === userData.country)?.country_code || ''}</p>
                        </div> 
                        <input
                            name='mobile_number'
                            className={`w-[70%] outline-none py-2 rounded-md border border-white/75 bg-transparent px-5 placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                            placeholder='Mobile No'
                            value={userData.Cust_PhoneNo || ''}
                            onChange={handleChange}
                            disabled={user}
                        />
                    </div> */}
                    <div className='w-[100%] py-2 h-[58px] rounded-md border border-white/75 '>
                        <p className='text-xs 2xl:text-sm text-white/70 px-5'>Mobile No</p>
                        <input
                            name='Mobile'
                            className={`w-full bg-transparent px-5 outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                            placeholder='Mobile No'
                            value={userData.Cust_PhoneNo || ''}
                            disabled={user}
                        />
                    </div>
                    {/* <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!user ? '' : 'cursor-not-allowed'}`}>
                        <p className='text-xs 2xl:text-sm text-white/70'>State</p>
                        <select
                            name='city'
                            className='w-full bg-transparent outline-none'
                            value={selectedState || ''}
                            onChange={handleStateChange}
                            disabled={user}
                        >
                            <option className='text-black'>--- Select ---</option>
                            {filteredStates.map((state) => (
                                <option key={state.name} value={state.name} className='text-black'>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className='w-[100%] py-2 h-[58px] rounded-md border border-white/75 '>
                        <p className='text-xs 2xl:text-sm text-white/70 px-5'>State</p>
                        <input
                            name='state'
                            className={`w-full bg-transparent px-5 outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                            placeholder='State'
                            value={userData.Cust_City || ''}
                            disabled={user}
                        />
                    </div>
                    <div className='w-[100%] py-2 h-[58px] rounded-md border border-white/75 '>
                        <p className='text-xs 2xl:text-sm text-white/70 px-5'>Withdraw Address</p>
                        <input
                            name='withdraw_wallet'
                            className={`w-full bg-transparent px-5 outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                            placeholder='Withdraw Address (USDT - Bep20)'
                            // value={userData.crypto_wallet ? userData.crypto_wallet : userData.deposit_wallet ? userData.deposit_wallet : ''}
                            value = {userData.withdraw_address || ''}
                            disabled={user}
                        />
                    </div>


                    <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!user ? '' : 'cursor-not-allowed'}`}>
                        <p className='text-xs 2xl:text-sm text-white/70'>Date of Birth</p>
                        <input
                            type='date'
                            name='dob'
                            className='bg-transparent outline-none'
                            value={userData.Cust_DOB ? formatDate(userData.Cust_DOB) : ''}
                            
                            disabled={user}
                        />
                    </div>
                </div>
            </div>
            <div className='w-[100%] py-2 mt-3 overflow-hidden mb-5 h-[100px] rounded-md border border-white/75 '>
                <p className='text-xs 2xl:text-sm text-white/70 px-5'>Address</p>
                <textarea
                    name='address'
                    className={`w-full h-full px-5 py-2 bg-transparent outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                    placeholder='Customer Address'
                    value={userData.Cust_Address || ''}
                    disabled={user}
                ></textarea>
            </div>

            {/* <div className='flex justify-center'>
                <div className='flex gap-5 mt-3'>
                    <button
                        onClick={handleUpdateProfile}
                        className='bg-[#00bb00] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md'
                    >
                        Update Profile
                    </button>
                </div>
            </div> */}
        </div>
    );
}
