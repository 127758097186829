import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useLocation } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import toast, { Toaster } from "react-hot-toast";
import { SERVER_URL } from "../../config";

Aos.init();


const Membership = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      const section = document.getElementById(location.state.scrollToSection);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);


  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [mobilenumber, setMobileNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]+$/;
    const nameRegex = /^[a-zA-Z\s]+$/;

    if (!fullname.trim()) {
      toast.error('Full name is required.');
      return;
    } else if (!nameRegex.test(fullname)) {
      toast.error('Full name should only contain letters and spaces.');
      return;
    }

    if (!email.trim() || !emailRegex.test(email)) {
      toast.error('A valid email address is required.');
      return;
    }

    if (!mobilenumber.trim() || !mobileRegex.test(mobilenumber)) {
      toast.error('Mobile number should contain only numbers.');
      return;
    }

    try {
      const response = await axios.post(`${SERVER_URL}/api/interests`, {
        fullname,
        email,
        mobilenumber
      });

      if (response.data.success) {
        toast.success('Thanks for showing interest!');
        setFullname('');
        setEmail('');
        setMobileNumber('');
      } else {
        toast.error('Failed to save details.');
      }
    } catch (error) {
      console.error('There was an error!', error);
      toast.error('Error saving details.');
    }
  };

  const handle = () => {
    window.location.href = "#"
  }

  return (
    <div className="text-white font-poppins">
      <div className='fixed inset-0 z-0'>
        <img src='/assets/NewMBCbg.png' className='h-full w-full object-cover object-top'></img>
      </div>
      <div className="relative z-10">
        <Header />
        <Toaster />

        <div className="sec-1">

          <div className="pt-28">
            <p className="text-4xl sm:text-5xl 2xl:text-7xl font-bold font-Artemus text-center text-shadow-custom" data-aos="fade-up" data-aos-duration="1000">Membership plan’s</p>
            <div className="flex justify-center items-center gap-5 px-5 mt-5" data-aos="zoom-in" data-aos-duration="1000">
              <p className="md:text-lg font-semibold text-sm">Basic Membership plan</p>
              <div className="w-36 bg-white rounded-full p-0.5 cursor-pointer">
                <a href="/FAQ"><div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1.5 px-5 text-sm rounded-full text-center font-semibold">Know more
                </div></a>
              </div>
            </div>
          </div>
          <div className="md:w-[80%] w-[95%] mx-auto mt-5 ">
            <div className="flex md:flex-row flex-col flex-wrap justify-center items-center py-3 lg:h-full sm:h-screen overflow-hidden gap-5">
              <div className="flip-card-subscribe grid content-center border rounded-2xl py-5 px-2 shadow-[inset #E43875 0px 0px 60px -12px ]" data-aos="fade-up" data-aos-duration="1000" style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", background: '#000' }}>
                <div className="flex flex-col items-center gap-3 ">
                  <p className="text-lg font-semibold">Member</p>
                  <p style={{
                    fontWeight: 700,
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    backgroundImage:
                      "linear-gradient(to right, #E43875, #F98736)",
                  }} className="text-2xl font-bold">$50</p>
                  <a href="/Signup">
                    <div className=" lg:w-36 bg-white rounded-full p-0.5 cursor-pointer">
                      <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1 px-5 text-sm rounded-full text-center font-semibold">Unlock Now
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="flip-card-subscribe rounded-2xl" data-aos="fade-up" data-aos-duration="1250">
                <div class="flip-card-inner rounded-2xl">
                  <div class="flip-card-front  bg-gradient-to-b from-[#E43875] to-[#F98736] border rounded-2xl grid content-center gap-5" >
                    <p className="text-lg font-semibold">Silver</p>
                    <div><button className="px-5 py-1 text-black font-bold bg-white rounded-full">Unlock Now</button></div>
                  </div>
                  <div class="flip-card-back p-5 border border-white bg-black rounded-2xl grid content-center gap-3" style={{ background: "#000" }}>
                    <p style={{
                      fontWeight: 700,
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      backgroundImage:
                        "linear-gradient(to right, #E43875, #F98736)",
                    }} className="text-2xl font-bold">$200</p>
                    <a href="/Signup" className="mx-auto">
                      <div className="w-36 bg-white rounded-full p-0.5 cursor-pointer">
                        <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1 px-5 text-sm rounded-full text-center font-semibold">Unlock Now
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flip-card-subscribe rounded-2xl" data-aos="fade-up" data-aos-duration="1500">
                <div class="flip-card-inner rounded-2xl">
                  <div class="flip-card-front  bg-gradient-to-b from-[#E43875] to-[#F98736] border rounded-2xl grid content-center gap-5" >
                    <p className="text-lg font-semibold">Gold</p>
                    <div><button className="px-5 py-1 text-black font-bold bg-white rounded-full">Unlock Now</button></div>
                  </div>
                  <div class="flip-card-back p-5 border border-white bg-black rounded-2xl grid content-center gap-3" style={{ background: "#000" }}>
                    <p style={{
                      fontWeight: 700,
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      backgroundImage:
                        "linear-gradient(to right, #E43875, #F98736)",
                    }} className="text-2xl font-bold">$600</p>
                    <a href="/Signup" className="mx-auto">
                      <div className="w-36 bg-white rounded-full p-0.5 cursor-pointer">
                        <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1 px-5 text-sm rounded-full text-center font-semibold">Unlock Now
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flip-card-subscribe rounded-2xl" data-aos="fade-up" data-aos-duration="1750">
                <div class="flip-card-inner rounded-2xl">
                  <div class="flip-card-front  bg-gradient-to-b from-[#E43875] to-[#F98736] border rounded-2xl grid content-center gap-5" >
                    <p className="text-lg font-semibold">Platinum</p>
                    <div><button className="px-5 py-1 text-black font-bold bg-white rounded-full">Unlock Now</button></div>
                  </div>
                  <div class="flip-card-back p-5 border border-white bg-black rounded-2xl grid content-center gap-3" style={{ background: "#000" }}>
                    <p style={{
                      fontWeight: 700,
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      backgroundImage:
                        "linear-gradient(to right, #E43875, #F98736)",
                    }} className="text-2xl font-bold">$1500</p>
                    <a href="/Signup" className="mx-auto">
                      <div className="w-36 bg-white rounded-full p-0.5 cursor-pointer">
                        <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1 px-5 text-sm rounded-full text-center font-semibold">Unlock Now
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flip-card-subscribe rounded-2xl" data-aos="fade-up" data-aos-duration="2000">
                <div class="flip-card-inner rounded-2xl">
                  <div class="flip-card-front  bg-gradient-to-b from-[#E43875] to-[#F98736] border rounded-2xl grid content-center gap-5" >
                    <p className="text-lg font-semibold">Executive</p>
                    <div><button className="px-5 py-1 text-black font-bold bg-white rounded-full">Unlock Now</button></div>
                  </div>
                  <div class="flip-card-back p-5 border border-white bg-black rounded-2xl grid content-center gap-3" style={{ background: "#000" }}>
                    <p style={{
                      fontWeight: 700,
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      backgroundImage:
                        "linear-gradient(to right, #E43875, #F98736)",
                    }} className="text-2xl font-bold">$3000</p>
                    <a href="/Signup" className="mx-auto">
                      <div className="w-36 bg-white rounded-full p-0.5 cursor-pointer">
                        <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1 px-5 text-sm rounded-full text-center font-semibold">Unlock Now
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flip-card-subscribe rounded-2xl" data-aos="fade-up" data-aos-duration="2250">
                <div class="flip-card-inner rounded-2xl">
                  <div class="flip-card-front  bg-gradient-to-b from-[#E43875] to-[#F98736] border rounded-2xl grid content-center gap-5" >
                    <p className="text-lg font-semibold">Ambassador</p>
                    <div><button className="px-5 py-1 text-black font-bold bg-white rounded-full">Unlock Now</button></div>
                  </div>
                  <div class="flip-card-back p-5 border border-white bg-black rounded-2xl grid content-center gap-3" style={{ background: "#000" }}>
                    <p style={{
                      fontWeight: 700,
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      backgroundImage:
                        "linear-gradient(to right, #E43875, #F98736)",
                    }} className="text-2xl font-bold">$5000</p>
                    <a href="/Signup" className="mx-auto">
                      <div className="w-36 bg-white rounded-full p-0.5 cursor-pointer">
                        <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1 px-5 text-sm rounded-full text-center font-semibold">Unlock Now
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flip-card-subscribe rounded-2xl" data-aos="fade-up" data-aos-duration="2250">
                <div class="flip-card-inner rounded-2xl">
                  <div class="flip-card-front  bg-gradient-to-b from-[#E43875] to-[#F98736] border rounded-2xl grid content-center gap-5" >
                    <p className="text-lg font-semibold">President</p>
                    <div><button className="px-5 py-1 text-black font-bold bg-white rounded-full">Unlock Now</button></div>
                  </div>
                  <div class="flip-card-back p-5 border border-white bg-black rounded-2xl grid content-center gap-3" style={{ background: "#000" }}>
                    <p style={{
                      fontWeight: 700,
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      backgroundImage:
                        "linear-gradient(to right, #E43875, #F98736)",
                    }} className="text-2xl font-bold">$10000</p>
                    <a href="/Signup" className="mx-auto">
                      <div className="w-36 bg-white rounded-full p-0.5 cursor-pointer">
                        <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1 px-5 text-sm rounded-full text-center font-semibold">Unlock Now
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className="md:w-[80%] w-[95%] mx-auto mt-5 flex md:flex-row flex-col flex-wrap justify-center items-center">
            <div class="cards__inner ">
              <div class="cards__card card">
                <p class="card__heading">Free Plan</p>
                <p class="card__price">$0/month</p>
                <ul class="card_bullets flow" role="list">
                  <li>Access to all features</li>
                  <li>Unlimited storage</li>
                  <li>No ads</li>
                </ul>
                <a class="card__cta cta" href="#basic">Get Started</a>
              </div>
              <div class="overlay cards__inner"></div>
            </div>
          </div>*/}
          <p className="text-center mt-10 text-2xl text-shadow-custom font-bold">100% Payout for 90 Days</p>
          <p className="text-center text-ellipsis lg:pb-20 font-bold mt-3">Get Started with Just <span className="">50$</span> Per Year</p>
        </div>

        {/*
        <div className="sec-2  min-h-screen font-poppins text-white lg:py-10 py-20 overflow-hidden">
          <div className="pt-20">
            <p className="text-4xl sm:text-5xl 2xl:text-7xl font-bold font-Artemus text-center text-shadow-custom" data-aos="fade-up" data-aos-duration="1000">Membership plan’s</p>
            <p className="text-center sm:text-lg font-semibold" data-aos="zoom-in" data-aos-duration="1250">Choose the perfect plan for you.</p>
          </div>
          <div className="sm:w-[85%] w-[90%] pt-10 pb-10 mx-auto flex lg:flex-row flex-col justify-center lg:gap-0 gap-10">
            <div className="lg:w-[50%] " data-aos="zoom-in" data-aos-duration="1500">
              <div className="flex justify-center items-center">
                <img src="../assets/212071.png" className="sm:w-9/12" />
              </div>
              <div className="mt-5">
                <p className="text-center font-semibold text-lg">Upgraded Membership</p>
                <div className="w-44 mx-auto bg-white rounded-full p-1 mt-5">
                  <div className="bg-gradient-to-r from-[#E43875] to-[#F98736] py-1.5 px-5 rounded-full text-center font-semibold cursor-pointer" onClick={handle}>Know more
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-[50%]">
              <div className="bg-white/25 border rounded-lg p-2 2xl:p-5" data-aos="fade-left" data-aos-duration="1000">
                <p className="font-semibold 2xl:text-lg"> Income Stream 1</p>
                <ul className="list-disc px-7 mt-2 flex flex-col gap-2">
                  <li className="font-semibold text-xs 2xl:text-base">Upgrade Commissions (Earn 10% on all Level 1 upgrades) </li>
                  <li className="font-semibold text-xs 2xl:text-base">Example: Referral upgrades from $100 to $1000, you earn $90 (10% of $900 difference)</li>
                </ul>
              </div>

              <div className="bg-white/25 border rounded-lg p-2 2xl:p-5 mt-3" data-aos="fade-left" data-aos-duration="1250">
                <p className="font-semibold 2xl:text-lg">Income Stream 2</p>
                <ul className="list-disc px-7 mt-2 flex flex-col gap-2">
                  <li className="font-semibold text-xs 2xl:text-base">Rank Up & Keep the Difference (Earn Up to 25% in overrides based on rank)</li>
                  <li className="font-semibold text-xs 2xl:text-base">Ranks: Club 200: Earn 5% to infinity, Club 1000: Earn 10% to infinity, Club 1 Million: Earn 25% to infinity.</li>
                  <li className="font-semibold text-xs 2xl:text-base"> Rule: Minimum 2 active legs, no leg {">"} 50% of target volume</li>
                </ul>
              </div>

              <div className="bg-white/25 border rounded-lg p-2 2xl:p-5 mt-3" data-aos="fade-left" data-aos-duration="1500">
                <p className="font-semibold 2xl:text-lg">Income Stream 3</p>
                <ul className="list-disc px-7 mt-2 flex flex-col gap-2">
                  <li className="font-semibold text-xs 2xl:text-base">Team Matching Bonuses (Match Up to 15 Levels of team income)</li>
                  <li className="font-semibold text-xs 2xl:text-base">Level 1: 30% match.</li>
                  <li className="font-semibold text-xs 2xl:text-base"> Levels 2-15: 5% match each.</li>
                  <li className="font-semibold text-xs 2xl:text-base">Example: With $600 membership, match incomes from $100, $500, and $600 members</li>
                </ul>
              </div>
            </div>
          </div>
        </div>*/}
        <form onSubmit={handleSubmit}>
          <div id="sec-3" className="sec-3  bg-cover min-h-screen font-poppins text-white ">
            <div className="xl:w-[75%] w-[90%]  py-10 mx-auto flex justify-center items-center min-h-screen">
              <div className="flex md:flex-row flex-col justify-center gap-10 border-2 rounded-lg lg:p-10 p-5" data-aos="fade-up" data-aos-duration="1000">
                <div className="lg:w-[45%] sm:w-[40%]">
                  <h1 className="lg:text-6xl text-5xl font-Artemus font-bold uppercase text-shadow-custom" data-aos="fade-up" data-aos-duration="1250">Interest form </h1>
                  <div className="md:w-[80%]">
                    <p className="font-semibold " data-aos="zoom-in" data-aos-duration="1500">Submit an interest form our MBC Representative will reach out to you with more information.</p>
                    <div className="w-32 h-0.5 bg-white mt-1" data-aos="fade-right" data-aos-duration="1750"></div>
                  </div>
                </div>


                <div className="lg:w-[55%] sm:w-[60%]">
                  <div className="bg-white rounded-lg p-5" data-aos="fade-up" data-aos-duration="1000">
                    <div className="lg:w-[60%] mx-auto">
                      <p className="text-black font-semibold leading-10 text-center">Are you ready to elevate your business to new heights?</p>
                    </div>
                    <div className="md:w-[85%] mx-auto mt-10 md:mt-0">
                      <div>
                        <label className="text-black/40 font-bold">Fullname</label>
                        <input type="text"
                          value={fullname}
                          onChange={(e) => setFullname(e.target.value)}
                          className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-full" />
                      </div>

                      <div className="mt-3">
                        <label className="text-black/40 font-bold">Email address</label>
                        <input type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-full" />
                      </div>

                      <div className="mt-3">
                        <label className="text-black/40 font-bold">Mobile number</label>
                        <input type="text"
                          value={mobilenumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-full" />
                      </div>

                      <div className="mt-5">
                        <button type="submit"
                          className="w-full rounded-2xl py-3 shadow-lg shadow-black/30 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-semibold">
                          Save & Next
                        </button>
                      </div>
                    </div>

                  </div>

                </div>


              </div>

            </div>
          </div>
        </form>
        <Footer />
      </div>
    </div>
  )
}

export default Membership