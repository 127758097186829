import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faChevronLeft, faChevronRight, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'


export default function AllMemeberReports() {
    const tableEntries = [
        {
            id: 1,
            showdetails: 'DTL',
            upline: 'UP',
            downline: 'DN',
            serialno: '1',
            custID: 'CUST123',
            username: 'user1',
            password: 'password123',
            sponsorID: 'SP123',
            custname: 'John Doe',
            email: 'johndoe@example.com',
            mobile: '123-456-7890',
            registerdate: '2024-09-16',
            activedate: '2024-09-17',
            lastactivedate: '2024-09-18'
        }
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }



    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 pr-3 md:pr-10 mt-5 font-poppins'>
                <div className=''>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                        <div className=''>
                            <p className='my-auto font-bold text-xl font-Artemus uppercase'>All Member Report <span className='font-poppins font-normal text-sm'> Users</span>
                            </p>
                        </div>

                    </div>
                    <div className='mb-5 border rounded-xl  text-sm'>
                        <div className='py-3 px-5 flex flex-col lg:flex-row items-center justify-between gap-3 md:gap-0 border-b'>
                            <p>All Customer Data with filter user</p>
                            <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Export To Word</button>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Export To Excel</button>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Export To Csv</button>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Export To Print</button>
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row items-center justify-between px-5 py-2'>
                            <p className='text-end px-3 my-auto font-bold'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                            <div className='flex flex-col md:flex-row items-center text-nowrap gap-3 md:w-[35%]'>
                                <p className='font-bold'>Search :</p>
                                <input className='w-[100%] py-1 rounded-md bg-transparent border outline-none px-3'></input>
                            </div>
                        </div>
                        <div className='overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center  text-[13px] text-nowrap'>
                            <thead className='uppercase font-bold border-y py-3'>
                                <tr>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2 py-3'>Serial No</th>
                                    <th className='px-2'>Cust ID</th>
                                    <th className='px-2'>User Name</th>
                                    <th className='px-2'>Password</th>
                                    <th className='px-2'>Sponser ID</th>
                                    <th className='px-2'>Cust Name</th>
                                    <th className='px-2'>Email</th>
                                    <th className='px-2'>Mobile No</th>
                                    <th className='px-2'>Register date</th>
                                    <th className='px-2'>Active Date</th>
                                    <th className='px-2'>Last Active Package</th>
                                </tr>
                                <tr className='border-t'>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2'></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Serial No'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Cust ID'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='User Name'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Password'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Sponsor ID'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Cust Name'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Email'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Mobile'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Register date'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Active date'></input></th>
                                    <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Last active package'></input></th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className=' h-16 text-sm text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{entity.id}</td>
                                        <td className='px-5 md:px-2'>{entity.showdetails}</td>
                                        <td className='px-5 md:px-2'>{entity.upline}</td>
                                        <td className='px-5 md:px-2'>{entity.downline}</td>
                                        <td className='px-5 md:px-2'><button className='p-1 bg-blue-500 rounded-md'>Login</button></td>
                                        <td className='px-5 md:px-2'>{entity.serialno}</td>
                                        <td className='px-5 md:px-2'>{entity.custID}</td>
                                        <td className='px-5 md:px-2'>{entity.username}</td>
                                        <td className='px-5 md:px-2'>{entity.password}</td>
                                        <td className='px-5 md:px-2'>{entity.sponsorID}</td>
                                        <td className='px-5 md:px-2'>{entity.custname}</td>
                                        <td className='px-5 md:px-2'>{entity.email}</td>
                                        <td className='px-5 md:px-2'>{entity.mobile}</td>
                                        <td className='px-5 md:px-2'>{entity.registerdate}</td>
                                        <td className='px-5 md:px-2'>{entity.activedate}</td>
                                        <td className='px-5 md:px-2'>{entity.lastactivedate}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
