import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../../config';

export default function DirectTeam() {
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const user = useSelector((state) => state.user);
  console.log(user, 'user')

  const directData = async () => {
    try {
      await axios.get(`${SERVER_URL}/api/fetchTeamDataofUser?id=${user[0].CusID}`).then((res) => {
        console.log(res, 'team response')
        if (res.data.status == true) {
          setData(res.data.TEAM.direct)
        } else {
          setData([])
        }
      })
    } catch (err) {
      console.log(err)
      setData([])
    }
  }
  useEffect(() => {
    directData()
  }, [user])

  console.log(data, 'direct')

  const filteredData = data.filter(entity =>
    entity.username.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedLevel === "" || entity.level === parseInt(selectedLevel))
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredData.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(data.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle dropdown change for entities per page
  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  // const handleLevelChange = (e) => {
  //   setSelectedLevel(e.target.value);
  //   setCurrentPage(1);
  // };

  const openImageInNewTab = (entity) => {
    window.open(entity.doc, '_blank');
  };

  // Calculate pagination details
  const totalPages = Math.ceil(data.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  function dateConvert(timestamp) {
    const dateObj = new Date(timestamp);

    const localDate = dateObj.toLocaleDateString();

    return localDate
  }
  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
        <p className='my-auto font-bold text-xl font-Artemus uppercase'>Direct Team Information</p>
        <div className='flex flex-col md:flex-row gap-5 md:gap-0 justify-between items-center mt-5 text-sm'>
          <div>
            <p>Username : <span className='font-bold'>{user[0].name.toUpperCase()}</span></p>
            {/* <p>Group Business : <span className='font-bold'>$ 5750</span></p> */}
          </div>
          {/* <div>
            <p>Date of Joining : 08-May-2024 10:56:31 AM</p>
            <p>Date of Activation : 11-May-2024 10:30:47 AM</p>
          </div> */}
        </div>
        <div className='rounded-2xl mt-5'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
            <div className='md:w-[35%] flex gap-3'>
              <input
                className='border rounded-md text-center bg-transparent w-[75%] py-1 outline-none placeholder:text-white'
                placeholder='Enter username'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {/* <div className='md:w-[20%] flex gap-3'>
                <select
                  className='border rounded-md text-center bg-transparent w-full py-1 outline-none'
                  value={selectedLevel}
                  onChange={handleLevelChange}
                >
                  <option value="" className='text-black'>Select Level</option>
                  {[...Array(10)].map((_, index) => (
                    <option key={index + 1} className='text-black' value={index + 1}>{index + 1}</option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
            <table className='w-full text-center  '>
              <thead className='text-sm uppercase font-bold border-b py-3 '>
                <tr>
                  <th className='py-3 px-2'>Sr.No.</th>
                  <th className='px-2'>Member Id</th>
                  <th className='px-2'>User Name</th>
                  <th className='px-2 '>Level</th>
                  <th className='px-2'>Date of Registration		</th>
                  <th className='px-2'>Date of Activation	</th>
                  <th className='px-2'>Email	</th>

                  {/* <th className='px-2 '>Active Package ($)</th>
                  <th className='px-2'>Group Biz ($)</th> */}
                </tr>
              </thead>
              <tbody className='divide-y'>
                {currentEntities.map((entity, index) => (
                  <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                    <td className='px-5 md:px-2'>{index + 1}</td>
                    <td className='px-5 md:px-2'>{entity.CusID}</td>
                    <td className='px-5 md:px-2'>{entity.username}</td>
                    <td className='px-5 md:px-2 '>{entity.level}</td>
                    <td className='px-5 md:px-2'>{dateConvert(entity.Entry_Date ?? entity.createdAt)}</td>
                    <td className='px-5 md:px-2'>{dateConvert(entity.Entry_Date ?? entity.createdAt)}</td>
                    <td className='px-5 md:px-2'>{entity.email}</td>
                    {/* <td className='px-5 md:px-2'>{entity.Groupbiz}</td> */}
                  </tr>
                ))}
                {/* Add empty rows if needed */}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}
