import React, { useState } from 'react'

export default function Withdrawonce() {

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Withdrawal Request
                </h1>
                <div className='border mt-2 rounded-md'>
                    <div className='px-5 py-2 border-b '>
                        <p className='text-lg font-bold'>Filter Your Search</p>
                    </div>
                    <div className='flex flex-col md:flex-row gap-3 px-5 py-2 items-center '>
                        <p className='text-sm font-bold text-nowrap'>Cust ID:</p>
                        <div className='md:w-[25%]'>
                            <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                        </div>
                        <button className='px-5 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Search</button>
                    </div>
                </div>
                <div className='border mt-5 rounded-md'>
                    <div className='px-5 py-2 border-b '>
                        <p className='font-bold'>Showing All Customer Records</p>
                    </div>
                    <div className='px-5 py-2  '>
                        <p className='font-bold'>Warning!</p>
                        <p>Record Not Found</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
