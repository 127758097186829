
import { legacy_createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 

const initialState = {
  user: [{
    email:'',
    CusID : null,
    name : ''
  }],
  admin_email:'',
  admin:'',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_user':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'SET_ADMIN_EMAIL':
        return {
          ...state,
          admin_email: action.payload,
    };
    case 'ADMIN_DETAIL':
      return {
        ...state,
        admin: action.payload,
    };
    default:
      return state;
  }
};  

export const setUser = (email) => ({
  type: 'SET_user',
  payload: email,
});

export const SET_TOKEN = (data) => ({
  type: 'SET_TOKEN',
  payload: data,
});

export const setAdminDetails = (data) => ({
  type: 'ADMIN_DETAIL',
  payload: data,
});

export const setAdminEmail = (email) => ({
  type: 'SET_ADMIN_EMAIL',
  payload: email,
});

const persistConfig = {
  key: 'root', 
  storage, 
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = legacy_createStore(persistedReducer);

const persistor = persistStore(store);

export { store, persistor };                                    